import HelperText from 'flowbite-react/lib/esm/components/FormControls/HelperText';
import { useField } from 'formik';
import React from 'react'

interface Props{
    placeholder: string;
    name: string;
    label?: string;
    type: string;
    required: boolean;
    className?: string;
}

export const MyTextInput = (props: Props) => {

    const [field, meta] = useField(props.name);

  return (
    <div className={props.className}>
        {props.label !== undefined && <div className="mb-2 block">
            <label className="text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor={props.name}>
                {props.label}{props.required && <span className='text-red-700 ml-1'>*</span>}
                </label>
        </div>}

        <div className="flex">
            <div className="relative w-full z-10">
                <input {...field}
                        className="block w-full border shadow-sm disabled:cursor-not-allowed disabled:opacity-50 rounded-lg p-2.5 text-sm border-gray-300 text-gray-900 focus:border-indigo-600 focus:ring-indigo-600 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-indigo-600 dark:focus:ring-indigo-600"
                        id={props.name}
                        type={props.type}
                        placeholder={props.placeholder}
                        required = {props.required} />
            </div>
        </div>
        {meta.touched && !!meta.error && <HelperText color='failure' className='-translate-y-2 -z-10 mt-0 text-center p-2 pt-3 mb-2 text-sm text-red-700 bg-red-100 rounded-b-lg dark:bg-red-200 dark:text-red-800'>{meta.error}</HelperText>}
  </div>
  )
}
