import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { Gig } from "../models/gig";

export default class GigStore {

    gigRegistry = new Map<string, Gig>();
    selectedGig: Gig | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = true;

    constructor() {
        makeAutoObservable(this)
    }

    get gigs(){
        return Array.from(this.gigRegistry.values());
    }

    loadGigs = async () => {
        try {
            const gigs = await agent.Gigs.list();
            gigs.forEach(gig =>{
                gig.date = new Date(gig.date!)
                this.addGigToRegistry(gig);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    addGigToRegistry = (gig: Gig) =>{
        this.gigRegistry.set(gig.id, gig);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    selectGig = (id: string) => {
        this.selectedGig = this.gigRegistry.get(id);
    }

    cancelSelectedGig = (id: string) => {
        this.selectedGig = undefined;
    }
}