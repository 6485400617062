import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { ContactFormValues } from "../models/contact";

export default class ContactStore {

    constructor() {
        makeAutoObservable(this)
    }

    sendContactRequest = async (data: ContactFormValues) => {
        try{
            await agent.Contact.contactRequest(data);
        } catch (error) {
            throw error;
        }
    }
}