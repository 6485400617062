import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { history } from "../..";
import agent from "../api/agent";
import { PasswordResetFormValues, SelectOption, User, UserFormValues } from "../models/user";
import { store } from "./store";

export default class UserStore {
    user: User | null = null;
    refreshTokenTimeout: any;

    constructor() {
        makeAutoObservable(this)
    }

    get isLoggedIn(){
        return !!this.user;
    }

    login = async (creds: UserFormValues) => {
        try{
            const user = await agent.Account.login(creds);
            store.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user);
            runInAction(() => this.user = user);
            history.push('/');
        } catch (error) {
            throw error;
        }
    }

    logout = () => {
        store.commonStore.setToken(null);
        window.localStorage.removeItem('jwt');
        this.user = null;
        history.push('/');
        this.stopRefreshTokenTimer();
    }

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            this.startRefreshTokenTimer(user);
        } catch (error) {
            console.log(error);
        }
    }

    setNickname = (name: string) => {
        if (this.user) this.user.nickname = name;
    }

    register = async (creds: UserFormValues) => {
        try{
            let copiedCreds = JSON.parse(JSON.stringify(creds));
            copiedCreds.country = copiedCreds.country?.value;
            copiedCreds.nationality = copiedCreds.nationality?.value;
            copiedCreds.yearsOfExperience = copiedCreds.yearsOfExperience?.value;
            copiedCreds.personalLiabilityInsurance = copiedCreds.personalLiabilityInsurance?.value;

            copiedCreds.riggingCertificates = copiedCreds.riggingCertificates?.map((certificate: SelectOption) => {
                return {riggingCertificateId: certificate?.value};
               });
            copiedCreds.languagesSpoken = copiedCreds.languagesSpoken?.map((lang: SelectOption) => {
             return {languageId: lang?.value};
            });
            copiedCreds.drivingLicenses = copiedCreds.drivingLicenses?.map((license: SelectOption) => {
                return {drivingLicenseId: license?.value};
               });

            copiedCreds.abseilingCertificates = copiedCreds.abseilingCertificates?.map((certificate: SelectOption) => {
            return {abseilingCertificateId: certificate?.value};
            });

            copiedCreds.mewpCertificates = copiedCreds.mewpCertificates?.map((certificate: SelectOption) => {
            return {mewpCertificateId: certificate?.value};
            });

            copiedCreds.riggingSpecialities = copiedCreds.riggingSpecialities?.map((speciality: SelectOption) => {
            return {riggingSpecialityId: speciality?.value};
            });

            copiedCreds.automations = copiedCreds.automations?.map((automation: SelectOption) => {
            return {automationId: automation?.value};
            });

            copiedCreds.cad = copiedCreds.cad?.map((cadSkill: SelectOption) => {
            return {cadSkillId: cadSkill?.value};
            });

            copiedCreds.hoistInspections = copiedCreds.hoistInspections?.map((hoistInspection: SelectOption) => {
            return {hoistInspectionId: hoistInspection?.value};
            });
            copiedCreds.birthdate = (creds.birthdate as Date).toDateString();
            const user = await agent.Account.register(copiedCreds);
            store.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user);
            runInAction(() => this.user = user);
            history.push('/');
        } catch (error) {
            throw error;
        }
    }

    requestPasswordReset = async (email: string) => {
        try{
            await agent.Account.requestPasswordReset(email);
        } catch (error) {
            throw error;
        }
    }

    resetPassword = async (data: PasswordResetFormValues) => {
        try{
            let result = await agent.Account.resetPassword(data);
            history.push('/');

            if(result){
                toast.success('Password updated');
            }else{
                toast.error('Failed to update the password');
            }
        } catch (error) {
            throw error;
        }
    }

    deleteAccount = async (user: User) => {
        try{
            let result = await agent.Account.deleteAccount(user);

            if(result) {
                store.commonStore.setToken(null);
                window.localStorage.removeItem('jwt');
                this.user = null;
                history.push('/');
                toast.success('Account deleted');
            }else{
                toast.error('Failed to delete the account');
            }
        } catch (error) {
            throw error;
        }
    }

    refreshToken = async () => {
        this.stopRefreshTokenTimer();
        try {
            const user = await agent.Account.refreshToken();
            runInAction(() => this.user = user);
            store.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user);
        } catch (error) {
            console.log(error);
        }
    }

    private startRefreshTokenTimer(user: User) {
        const jwtToken = JSON.parse(atob(user.token.split('.')[1]));
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (900 * 1000);
        this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}