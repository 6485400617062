import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Link } from 'react-router-dom'

export const Forbidden = () => {
  return (
    <div className='py-12'>
        <h1 className="text-4xl font-bold tracking-tight sm:text-center sm:text-6xl">Something went wrong</h1>
        <div className="text-lg leading-8 text-gray-600 text-center text-4xl my-10 flex align-middle items-center justify-center">
            <ExclamationTriangleIcon className="h-10 w-10 mr-4" aria-hidden="true" />You are not allowed to view this page
        </div>
        <div className="mt-8 flex gap-x-4 sm:justify-center">
        <Link to='/' className="inline-block rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700">
            Back to Homepage
        </Link>

        </div>
  </div>
  )
}
