import React, { Fragment } from 'react'

export const PrivacyPolicy = () => {
  return (
    <Fragment>
    <h2 className="my-6 text-3xl font-bold tracking-tight">Privacy policy</h2>
    <section>
        <p className='my-3'>
            <h3 className="text-2xl font-bold tracking-tight mb-3">Data protection</h3>
            <h4 className="text-1xl font-bold tracking-tight mb-2">Responsible for data processing</h4>
            Captain Howdy and Kosma  Szostak
        </p>

        <p className='my-3 mb-6'>
            <h4 className="text-1xl font-bold tracking-tight mb-2">The company's privacy policy follows</h4>
            <div>Showstak Automation</div>
            <div>Telephone: +48 600 002 314 </div>
            <div>Email: info@stage-riggers.com</div>
            <div>Address: Gospodarcza 26 Lublin 20-213</div>
            <div className='mt-3'>If you have any questions about this data protection declaration, please contact us directly by e-mail.</div>
        </p>
        

        <p className='my-3 text-justify'>
            <h3 className="text-2xl font-bold tracking-tight mb-3">1. Collection and processing of http log data</h3>
            <div>When you visit our website and each time a file is accessed, our web server collects log information about these processes (e.g. browser type, date and time of access). This is not personal data. It is therefore not possible for us to assign the data collected to a specific natural person.
All of this information is evaluated by us exclusively for statistical purposes. A transfer to third parties does not take place. We do not link this data to other data. The data is also regularly deleted within one week after a statistical evaluation.</div>
        </p>

        <p className='my-3 text-justify'>
            <h3 className="text-2xl font-bold tracking-tight mb-3">2. Use of personal data</h3>
            <div>Personal data will only be collected by us and only to the extent that you make it available to us yourself with your knowledge. In particular, this personal data will only be used for the purpose of establishing contact if you have given us your express consent to do so.
You have the right to withdraw consent at any time.
The purposes of processing are stated in the appropriate places on our websites and are not subject to any subsequent changes. The data will only be passed on to third parties without your consent if we are legally obliged to do so.</div>
        </p>

        <p className='my-3 text-justify'>
            <h3 className="text-2xl font-bold tracking-tight mb-3">3. Newsletters</h3>
            <div>If you want to order our newsletter, in addition to your e-mail address, we need confirmation that you are the owner of the e-mail address provided and that you agree to receive the newsletter. This data is only collected for the purpose of being able to send you the newsletter and to document our authorization in this regard. The data will not be passed on to third parties. You can revoke your subscription to the newsletter and your consent to the storage of your e-mail address at any time.</div>
        </p>

        <p className='my-3 text-justify'>
            <h3 className="text-2xl font-bold tracking-tight mb-3">4. Use of Cookies</h3>
            <div>Cookies are data sets that are sent from the web server to the user's web browser and stored there for later retrieval. You can decide for yourself whether cookies can be collected by setting your browser in such a way that you are informed before a cookie is saved and it is only saved if you expressly accept this.
We only use cookies for the purpose of obtaining information about the use of our website and for statistical purposes.
Our cookies contain the following data sets:
The data records do not contain any personal information. It will not be merged with any personal data you have provided.</div>
        </p>

        <p className='my-3 text-justify'>
            <h3 className="text-2xl font-bold tracking-tight mb-3">5. Right to information</h3>
            <div>You have the right to receive information about the data stored about you at any time, including the origin and recipient of your data and the purpose of data processing. Please address your request in writing or by e-mail to our addresses below.
This privacy policy only applies to content on our servers and does not cover the websites linked from our site.</div>
        </p>



 


 


 


 



    </section>
    </Fragment>

  )
}