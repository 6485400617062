import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { MyTextInput } from '../common/form/MyTextInput'
import { useStore } from '../stores/store'
import { observer } from 'mobx-react-lite';
import { Alert, Spinner } from 'flowbite-react';
import * as Yup from 'yup';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export default observer(function RequestPasswordResetPage() {

  const {userStore} = useStore();
  const [submitted, setSubmitted] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string().required('Email is required').email("Email not valid"),
    })

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md">
                  <div>
                  <img src='/assets/logo.svg' className="mx-auto h-36 w-auto rounded-lg" alt='Stage Riggers' />
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                      Enter your email to request a password reset
                    </h2>
                  </div>
                  <Formik
                  validationSchema={validationSchema}
                    initialValues={{email:''}}
                    onSubmit={(values, {resetForm}) => {
                        userStore.requestPasswordReset(values.email);
                        resetForm();
                        setSubmitted(true);
                    }}
                    >
                      {({handleSubmit, isSubmitting, isValid, dirty}) => (
                        <Form className="mt-8" autoComplete='off' onSubmit={handleSubmit}>
                            <MyTextInput placeholder={'Email address'} name={'email'} type={'email'} required={true} label={'Your email'} className='mb-2' />
                          <div className='mt-3'>
                            <button
                              type="submit"
                              disabled={!isValid || !dirty || isSubmitting}
                              className="group disabled:opacity-50 relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              {isSubmitting ? (<Spinner aria-label="large spinner" size="md" color="purple" />) : ("Submit") }
                            </button>
                          </div>
                        </Form>
                      )}
                  </Formik>
                  {submitted === true &&
                        <Alert
                            className='mt-5'
                            color="info"
                            additionalContent={
                            <React.Fragment>
                                <div className="mt-2 mb-4 text-sm text-blue-700 dark:text-blue-800">
                                    Please check your email, if this account exists you should recieve a message shortly.
                                </div>
                            </React.Fragment>}
                            icon={InformationCircleIcon}
                            >
                            <h3 className="text-lg font-medium text-blue-700 dark:text-blue-800">
                                Password reset requested
                            </h3>
                        </Alert>
                    }
                  </div>
              </div>
  )
})
