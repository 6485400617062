import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { MyTextInput } from '../common/form/MyTextInput'
import { useStore } from '../stores/store'
import { observer } from 'mobx-react-lite';
import { Alert, Spinner } from 'flowbite-react';
import * as Yup from 'yup';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { CustomTextArea } from '../common/form/CustomTextArea';

export default observer(function ContactPage() {

  const {contactStore} = useStore();
  const [submitted, setSubmitted] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string().required('Email is required').email("Email not valid"),
    firstname: Yup.string().required('Firstname is required'),
    surname: Yup.string().required('Surname is required'),
    companyAddress: Yup.string().required('Company address is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    taxId: Yup.string().required('Tax id is required'),
    message: Yup.string().required('Message required'),
    companyName: Yup.string().required('Company name is required')
    })

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md">
                  <div>
                  <img src='/assets/logo.svg' className="mx-auto h-36 w-auto rounded-lg" alt='Stage Riggers' />
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                      Contact Request
                    </h2>
                  </div>
                  <Formik
                  validationSchema={validationSchema}
                    initialValues={{email:'', firstname:'', surname:'', companyAddress:'', phoneNumber:'', taxId:'', message:'', companyName:'', error: null}}
                    onSubmit={(values, {resetForm, setErrors}) =>  {
                        contactStore.sendContactRequest(values).catch(error =>{
                            setErrors({error});
                            console.log(values);
                            console.log(error);
                          })
                        resetForm();
                        setSubmitted(true);
                    }}
                    >
                      {({handleSubmit, isSubmitting, errors, isValid, dirty}) => (
                        <Form className="mt-8" autoComplete='off' onSubmit={handleSubmit}>
                            <MyTextInput placeholder={'Email address'} name={'email'} type={'email'} required={true} label={'Contact email'} className='mb-2' />
                            <MyTextInput placeholder={'Firtname'} name={'firstname'} type={'text'} required={true} label={'Your firstname'} className='mb-2' />
                            <MyTextInput placeholder={'Surname'} name={'surname'} type={'text'} required={true} label={'Your surname'} className='mb-2' />
                            <MyTextInput placeholder={'Company name'} name={'companyName'} type={'text'} required={true} label={'Company name'} className='mb-2' />
                            <MyTextInput placeholder={'Company address'} name={'companyAddress'} type={'text'} required={true} label={'Company address'} className='mb-2' />
                            <MyTextInput placeholder={'Tax id'} name={'taxId'} type={'text'} required={true} label={'Company tax id'} className='mb-2' />
                            <MyTextInput placeholder={'Phone number'} name={'phoneNumber'} type={'text'} required={true} label={'Contact phone number'} className='mb-2' />
                            <CustomTextArea placeholder={'Description'} name={'message'} required={true} label={'Description of the demand for work at height'} className='mb-2' />

                          <div className='mt-8'>
                            <button
                              type="submit"
                              disabled={!isValid || !dirty || isSubmitting}
                              className="group disabled:opacity-50 relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              {isSubmitting ? (<Spinner aria-label="large spinner" size="md" color="purple" />) : ("Submit") }
                            </button>
                          </div>
                        </Form>
                      )}
                  </Formik>
                  {submitted === true &&
                        <Alert
                            className='mt-5'
                            color="info"
                            additionalContent={
                            <React.Fragment>
                                <div className="mt-2 mb-4 text-sm text-blue-700 dark:text-blue-800">
                                    Our staff will respond within 24 hours.
                                </div>
                            </React.Fragment>}
                            icon={InformationCircleIcon}
                            >
                            <h3 className="text-lg font-medium text-blue-700 dark:text-blue-800">
                                Contact request sent successfully
                            </h3>
                        </Alert>
                    }
                  </div>
              </div>
  )
})
