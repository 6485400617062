import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { history } from '../..';
import { Gig } from '../models/gig';
import { Profile, Rate, Skill } from '../models/profile';
import { PasswordResetFormValues, User, UserFormValues } from '../models/user';
import { ContactFormValues } from '../models/contact';

import { store } from '../stores/store';

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)     
    })
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`
    return config;
})

axios.interceptors.response.use(async response => {
    console.log(response);
    if(process.env.NODE_ENV === 'development') {
        await sleep(1000);
    }

    return response;
}, async (error: AxiosError) => {
    const {data, status, config, headers}: {data: any; status: number, config: AxiosRequestConfig, headers: any} = error.response!;
    console.log(status);
    switch (status) {
        case 400:
            
            if(typeof data === 'string') {
                if(process.env.NODE_ENV === 'development') {
                        await sleep(1000);
                    }
                toast.error(data);
            }

            if(config.method === 'get' && data.errors.hasOwnProperty('id')) {
                history.push('/not-found');
            }

            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key])
                    }
                }
                throw modalStateErrors.flat();
            }
            break;
        case 401:
            if (status === 401 && headers['www-authenticate']?.startsWith('Bearer error="invalid_token"')) {
                store.userStore.logout();
                toast.error('Session expired - please login again');
            }
            history.push('/sign-in');
            break;
        case 403:
            history.push('/forbidden');
            break;
        case 404:
            history.push('/not-found');
            break;
        case 500:
            store.commonStore.setServerError(data);
            history.push('/server-error');
            break;
    }
    return Promise.reject(error);
})

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T> (url: string) => axios.delete<T>(url).then(responseBody)
}

const Gigs = {
    list: () => requests.get<Gig[]>('/gigs')
}

const Account = {
    current: () => requests.get<User>('/account'),
    login: (user: UserFormValues) => requests.post<User>('/account/login', user),
    register: (user: UserFormValues) => requests.post<User>('/account/register', user),
    requestPasswordReset: (email: string) => {
        requests.post<void>(`account/requestPasswordReset?email=${email}`, {})
    },
    resetPassword: (passwordReset: PasswordResetFormValues) => requests.post<boolean>('account/passwordReset', passwordReset),
    deleteAccount: (user: User) => requests.post<boolean>(`account/deleteAccount`, user),
    refreshToken: () => requests.post<User>('/account/refreshToken', {})
}

const Profiles = {
    get: (nickname: string) => requests.get<Profile>(`/profile/${nickname}`),
    getRates: (nickname: string) => requests.get<Rate>(`/profile/rates/${nickname}`),
    getSkills: (nickname: string) => requests.get<Skill>(`/profile/skills/${nickname}`),
    updateProfilePersonalDetails: (profile: Partial<Profile>) => requests.put(`/profile/personalDetails`, profile),
    updateProfileRates: (rates: Partial<Rate>) => requests.put(`/profile/rates`, rates),
    updateProfileSkills: (skills: Partial<Skill>) => requests.put(`/profile/skills`, skills)
}

const Contact = {
    contactRequest: (formData: ContactFormValues) => requests.post<void>('/contact/contactRequest', formData)
}

const agent = {
    Gigs,
    Account,
    Profiles,
    Contact
}

export default agent;