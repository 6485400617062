import React from 'react'
import {Spinner} from 'flowbite-react';

interface Props {
    content?: string;
}

export const LoadingComponent = ({content = 'Loading...'}: Props) => {
  return (
    <div className="flex flex-col justify-center items-center p-4 rounded-lg col-span-full">
      <Spinner aria-label="large spinner" size="xl" color="purple" />
      <p className="text-gray-500 p-0 mt-2">{content}</p>
    </div>
  )
}
