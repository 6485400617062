import HelperText from 'flowbite-react/lib/esm/components/FormControls/HelperText';
import { useField } from 'formik';
import React, { useState } from 'react';
import DatePicker from "react-widgets/DatePicker";

interface Props{
  placeholder?: string;
  name?: string;
  label?: string;
  type?: string;
  required?: boolean;
  className?: string;
}

export const DateInput = (props: Props) => {

    const [dateError, setdateError] = useState(false);
    const [field, meta, helpers] = useField(props.name!);

  return (
    <div className={props.className}>
        <div className="mb-2 block z-20">
            <label className="text-sm font-medium text-gray-900 dark:text-gray-300">{props.label}<span className='text-red-700 ml-1'>*</span></label>
        </div>
        <DatePicker
          name={props.name}
          placeholder={props.placeholder}
          valueFormat={{ dateStyle: "short" }}
          onFocus={()=>setdateError(false)}
          onBlur={()=>{ if(field.value === null){setdateError(true)} }}
          onChange={value => {helpers.setValue(value)}}
        />
        {dateError && <HelperText color='failure' className='-translate-y-1 mt-0 text-center p-2 pt-2 mb-2 text-sm text-red-700 bg-red-100 rounded-b-lg dark:bg-red-200 dark:text-red-800'>Birthdate is required</HelperText>}
  </div>
  )
}
