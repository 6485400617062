import { Formik, Form, Field } from 'formik';
import { Fragment, useState } from 'react';
import { MyTextInput } from '../common/form/MyTextInput';
import * as Yup from 'yup';
import { useStore } from '../stores/store';
import { Checkbox, Spinner } from 'flowbite-react';
import { ValidationErrors } from '../../features/errors/ValidationErrors';
import Select from 'react-select'
import HelperText from 'flowbite-react/lib/esm/components/FormControls/HelperText';
import { DateInput } from '../common/form/DateInput';
import { nationalityList, countryList, riggingCertificates, abseilingCertificates, supportedDrivingLicenses, languages, yearsOfExperienceList, hoistInspections, cad, automations, riggingSpecialities, mewpCertificates } from '../common/constantData';
import { Link } from 'react-router-dom';


export const SignUpPage = () => {

    const [nationalityError, setNationalityError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    // const [yearsOfExperienceError, setYearsOfExperienceError] = useState(false);
    
    const {userStore} = useStore();

    const validationSchema = Yup.object({
        email: Yup.string().required('Email is required').email(),
        nickname: Yup.string().required('Nickname is required'),
        firstname: Yup.string().required('Firstname is required'),
        nationality: Yup.object().required('Nationality is required'),
        streetAddress: Yup.string().required('Street address is required'),
        postcode: Yup.string().required('Postcode is required'),
        country: Yup.object().required('Country is required'),
        city: Yup.string().required('City is required'),
        airport: Yup.string(),
        birthdate: Yup.date().required('Date is required'),
        phoneNumber: Yup.string().required('Phone number is required'),
        secondaryPhoneNumber: Yup.string(),
        emergencyPersonName: Yup.string(),
        emergencyPersonPhone: Yup.string(),
        surname: Yup.string().required('Surname is required'),
        consent: Yup.bool().required('Privacy policy consent is required').oneOf([true], 'You must accept the terms and conditions'),
        password: Yup.string().required('Password is required').matches(
          /.{8,20}$/,
          "Must be between 8 and 20 characters long"
        ),
        passwordRepeat: Yup.string().required('Password repeat is required').oneOf([Yup.ref('password'), null], 'Passwords must match')
    });


  return (
    <Fragment>
        <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-lg">
                  <div>
                  <img src='/assets/logo.svg' className="mx-auto h-36 w-auto rounded-lg" alt='Stage Riggers' />
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                      Sign up for an account
                    </h2>
                  </div>
                  <Formik
                        validationSchema={validationSchema}
                        initialValues={{
                          email:'',
                          password:'',
                          passwordRepeat:'',
                          nationality: {'value':'','label':'Select...'},
                          country: {'value':'','label':'Select...'},
                          birthdate: null,
                          riggingCertificates: null,
                          abseilingCertificates: null,
                          mewpCertificates: null,
                          riggingSpecialities: null,
                          automations: null,
                          cad: null,
                          hoistInspections: null,
                          languagesSpoken: null,
                          drivingLicenses: null,
                          yearsOfExperience: null,
                          personalLiabilityInsurance: null,
                          consent: false,
                          error: null}}
                    onSubmit={(values, {setErrors}) =>  userStore.register(values).catch(error =>{
                      
                      setErrors({error});
                      console.log(values);
                      console.log(error);
                    }
                      )}
                        >
                    {({ values, handleSubmit, setFieldValue, isSubmitting, errors, isValid, dirty }) => (
                        <Form className="mt-8" autoComplete='off' onSubmit={handleSubmit}>
                          
                            <h3 className="my-4 text-center text-2xl font-bold tracking-tight text-gray-900">Login data</h3>
                            <MyTextInput placeholder={'Email address'} name={'email'} type={'email'} required={true} label={'Your email'} className='mb-2' />
                            <MyTextInput placeholder={'Password'} name={'password'} type={'password'} required={true} label={'Your password'} className='mb-2' />
                            <MyTextInput placeholder={'Repeat password'} name={'passwordRepeat'} type={'password'} required={true} label={'Repeat password'} className='mb-3' />
                            
                            <h3 className="my-4 text-center text-2xl font-bold tracking-tight text-gray-900">Personal info</h3>
                            <MyTextInput placeholder={'Firstname'} name={'firstname'} type={'text'} required={true} label={'Your firstname'} className='mb-2' />
                            <MyTextInput placeholder={'Nickname'} name={'nickname'} type={'text'} required={true} label={'Your nickname'} className='mb-2' />
                            <MyTextInput placeholder={'Surname'} name={'surname'} type={'text'} required={true} label={'Your surname'} className='mb-2' />

                            <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Your nationality<span className='text-red-700 ml-1'>*</span></label>
                            </div>
                            <Select
                              className="react-select-container mb-2 z-50"
                              classNamePrefix="react-select"
                              onFocus={()=>setNationalityError(false)} onBlur={()=>{ if(values.nationality.value === ''){setNationalityError(true)} }} isSearchable={true} options={nationalityList} required value={values.nationality} onChange={(value) => setFieldValue("nationality", value)} />
                            {nationalityError && <HelperText color='failure' className='-translate-y-3 mt-0 text-center p-2 pt-2 mb-2 text-sm text-red-700 bg-red-100 rounded-b-lg dark:bg-red-200 dark:text-red-800'>Nationality is required</HelperText>}
                            
                            <MyTextInput placeholder={'Street address'} name={'streetAddress'} type={'text'} required={true} label={'Your street address'} className='mb-2' />
                            <MyTextInput placeholder={'Postcode'} name={'postcode'} type={'text'} required={true} label={'Your postcode'} className='mb-2' />

                            <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Your country of residence<span className='text-red-700 ml-1'>*</span></label>
                            </div>
                            <Select className='react-select-container mb-2 z-40' classNamePrefix="react-select" onFocus={()=>setCountryError(false)} onBlur={()=>{ if(values.country.value === ''){setCountryError(true)} }} isSearchable={true} options={countryList} required value={values.country} onChange={(value) => setFieldValue("country", value)} />
                            {countryError && <HelperText color='failure' className='-translate-y-3 mt-0 text-center p-2 pt-2 mb-2 text-sm text-red-700 bg-red-100 rounded-b-lg dark:bg-red-200 dark:text-red-800'>Country is required</HelperText>}

                            <MyTextInput placeholder={'City'} name={'city'} type={'text'} required={true} label={'Your city of residence'} className='mb-2' />
                            <MyTextInput placeholder={'Airport'} name={'airport'} type={'text'} required={false} label={'Your preferred airport'} className='mb-2' />
                          
                            <MyTextInput placeholder={'Phone number'} name={'phoneNumber'} type={'text'} required={true} label={'Your phone number'} className='mb-2' />
                            <MyTextInput placeholder={'Secondary phone number'} name={'secondaryPhoneNumber'} type={'text'} required={false} label={'Your secondary phone number'} className='mb-2' />
                            <DateInput
                              label='Birthdate'
                              placeholder="dd/mm/yyyy"
                              name='birthdate'
                              className='mb-2 z-20'
                              
                            />
                            <MyTextInput placeholder={'Emergency person name'} name={'emergencyPersonName'} type={'text'} required={false} label={'Your emergency person contact name'} className='mb-2' />
                            <MyTextInput placeholder={'Emergency person phone number'} name={'emergencyPersonPhone'} type={'text'} required={false} label={'Your emergency person contact phone number'} className='mb-2' />
                            
                            <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Do you currently have personal liability insurance</label>
                            </div>
                            <Select className='react-select-container mb-2' classNamePrefix="react-select" isSearchable={false} options={[
                            { value: 'Yes', label: 'Yes' },
                            { value: 'No', label: 'No' }
                          ]} value={values.personalLiabilityInsurance} onChange={(value) => setFieldValue("personalLiabilityInsurance", value)} />

                            <h3 className="my-4 text-center text-2xl font-bold tracking-tight text-gray-900">My Rates</h3>
                            <MyTextInput placeholder={'Rate as crew leader'} name={'rateAsCrewLeader'} type={'text'} required={false} label={'Your expected daily rate as crew leader (10 hours)'} className='mb-2' />
                            <MyTextInput placeholder={'Rate as rigger'} name={'rateAsRigger'} type={'text'} required={false} label={'Your expected daily rate as rigger (10 hours)'} className='mb-2' />
                            
                            <h3 className="my-4 text-center text-2xl font-bold tracking-tight text-gray-900">My Skills</h3>
                            
                            <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Your rigging certificates</label>
                            </div>
                            <Select className='react-select-container mb-2' classNamePrefix="react-select" isSearchable={false} options={riggingCertificates} isMulti required value={values.riggingCertificates} onChange={(value) => setFieldValue("riggingCertificates", value)} />






                            <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Your abseiling certificates</label>
                            </div>
                          
                            <Select className='react-select-container mb-2'
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    options={abseilingCertificates}
                                    isMulti
                                    required
                                    value={values.abseilingCertificates}
                                    onChange={(value) => setFieldValue("abseilingCertificates", value)} />

                            <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Your MEWP certificates</label>
                            </div>
                          
                            <Select className='react-select-container mb-2'
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    options={mewpCertificates}
                                    isMulti
                                    required
                                    value={values.mewpCertificates}
                                    onChange={(value) => setFieldValue("mewpCertificates", value)} />

                            <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Your rigging specialities</label>
                            </div>
                          
                            <Select className='react-select-container mb-2'
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    options={riggingSpecialities}
                                    isMulti
                                    required
                                    value={values.riggingSpecialities}
                                    onChange={(value) => setFieldValue("riggingSpecialities", value)} />

                            <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Automations</label>
                            </div>
                          
                            <Select className='react-select-container mb-2'
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    options={automations}
                                    isMulti
                                    required
                                    value={values.automations}
                                    onChange={(value) => setFieldValue("automations", value)} />

                            <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">CAD</label>
                            </div>
                          
                            <Select className='react-select-container mb-2'
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    options={cad}
                                    isMulti
                                    required
                                    value={values.cad}
                                    onChange={(value) => setFieldValue("cad", value)} />

                            <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Hoist inspections</label>
                            </div>
                          
                            <Select className='react-select-container mb-2'
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    options={hoistInspections}
                                    isMulti
                                    required
                                    value={values.hoistInspections}
                                    onChange={(value) => setFieldValue("hoistInspections", value)} />
                          











                            <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Languages spoken</label>
                            </div>
                          
                            <Select className='react-select-container mb-2' classNamePrefix="react-select" isSearchable={false} options={languages} isMulti required value={values.languagesSpoken} onChange={(value) => setFieldValue("languagesSpoken", value)} />
                          
                            <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Driving licenses</label>
                            </div>
                          
                            <Select className='react-select-container mb-2' classNamePrefix="react-select" isSearchable={false} options={supportedDrivingLicenses} isMulti required value={values.drivingLicenses} onChange={(value) => setFieldValue("drivingLicenses", value)} />
                          
                            <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Numer of years of experience in rigging</label>
                            </div>
                            <Select className='react-select-container mb-2' classNamePrefix="react-select" isSearchable={false} options={yearsOfExperienceList} value={values.yearsOfExperience} onChange={(value) => setFieldValue("yearsOfExperience", value)} />
                            {/* {yearsOfExperienceError && <HelperText color='failure' className='-translate-y-3 z-5 mt-0 text-center p-2 pt-2 mb-2 text-sm text-red-700 bg-red-100 rounded-b-lg dark:bg-red-200 dark:text-red-800'>Years of experience are required</HelperText>} */}
                            <div className="mt-5 block">
                              <div className="flex items-center justify-center mb-3">
                              <span className='mr-1 text-red-700'>*</span>
                                <Field type="checkbox" name="consent" className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-indigo-600 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label htmlFor="consent" className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">I consent to the <Link className='hover:underline text-indigo-600 dark:text-indigo-600' to='/privacypolicy'>privacy policy</Link>.</label>
                              </div>
                            </div>

                        <div className='mt-6'>
                        { errors !== undefined && errors !== null && <ValidationErrors className="mb-4" errors={errors.error} /> }
                        { !isValid && errors?.error == null && <ValidationErrors className="mb-4" errors={["Some of required fields are missing"]} /> }
                          <button
                            disabled={!isValid || !dirty || isSubmitting}
                            type="submit"
                            className="group relative disabled:opacity-50 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            {isSubmitting ? (<Spinner aria-label="large spinner" size="md" color="purple" />) : ("Sign up") }
                          </button>
                        </div>
                      </Form>
                    )}
                </Formik>
                </div>
              </div>
    </Fragment>
  )
}
