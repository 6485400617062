import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { Profile, Rate, Skill  } from "../models/profile";
import { SelectOption } from "../models/user";
import { store } from "./store";

export default class ProfileStore {
    profile: Profile | null = null;
    rates: Rate | null = null;
    skills: Skill | null = null;
    loadingProfile = false;
    loading = false;
    loadingRates = false;
    loadingSkills = false;

    constructor() {
        makeAutoObservable(this);
    }

    get isCurrentUser() {
        if (store.userStore.user && this.profile) {
            return store.userStore.user.nickname === this.profile.nickname;
        }
        return false;
    }

    loadProfile = async (nickname: string) => {
        this.loadingProfile = true;
        try {
            const profile = await agent.Profiles.get(nickname);
            console.log(profile);
            runInAction(() => {
                this.profile = profile;
                this.loadingProfile = false;
            })
        } catch (error) {
            runInAction(() => this.loadingProfile = false);
        }
    }

    updateProfile = async (profile: Partial<Profile>) => {
        this.loading = true;
        try {
            //profile.email = profile.email?.trim();
            let copiedProfile = JSON.parse(JSON.stringify(profile));
            copiedProfile.country = copiedProfile.country?.value;
            copiedProfile.personalLiabilityInsurance = copiedProfile.personalLiabilityInsurance?.value;
            copiedProfile.nickname = (copiedProfile.nickname as String)?.trim();
            
            await agent.Profiles.updateProfilePersonalDetails(copiedProfile);
            runInAction(() => {
                if (copiedProfile.nickname && copiedProfile.nickname !== store.userStore.user?.nickname) {
                    store.userStore.setNickname(copiedProfile.nickname);
                }
                this.profile = {...this.profile, ...copiedProfile as Profile};
                this.loading = false;
                window.history.pushState({}, '', `/profile/${copiedProfile.nickname}`);
                toast.success('Profile updated');
            })
        } catch (error) {
            runInAction(() =>{
                this.loading = false;
                toast.error('Personal details could not be updated');
            });
        }
    }

    loadRates = async (nickname: string) => {
        this.loadingRates = true;
        try {
            const rates = await agent.Profiles.getRates(nickname);
            console.log(rates);
            runInAction(() => {
                this.rates = rates;
                this.loadingRates = false;
            })
        } catch (error) {
            runInAction(() => this.loadingRates = false);
        }
    }

    updateRates = async (rates: Partial<Rate>) => {
        this.loading = true;
        try {
            rates.rateAsRigger = rates.rateAsRigger?.trim();
            rates.rateAsCrewLeader = rates.rateAsCrewLeader?.trim();
            
            await agent.Profiles.updateProfileRates(rates);
            runInAction(() => {
                this.rates = {...this.rates, ...rates as Rate};
                this.loading = false;
                toast.success('Rates updated');
            })
        } catch (error) {
            runInAction(() =>{
                this.loading = false;
                toast.error('Rates could not be updated');
            });
        }
    }

    loadSkills= async (nickname: string) => {
        this.loadingSkills = true;
        try {
            const skills = await agent.Profiles.getSkills(nickname);
            console.log(skills);
            skills.riggingCertificates = skills.riggingCertificates?.map((certifiacte: any) => {
                return {value: certifiacte.riggingCertificateId , label: certifiacte.riggingCertificateType}
            });
            skills.abseilingCertificates = skills.abseilingCertificates?.map((certifiacte: any) => {
                return {value: certifiacte.abseilingCertificateId , label: certifiacte.abseilingCertificateType}
            });
            skills.mewpCertificates = skills.mewpCertificates?.map((certifiacte: any) => {
                return {value: certifiacte.mewpCertificateId , label: certifiacte.mewpCertificateType}
            });
            skills.cad = skills.cad?.map((cadSkill: any) => {
                return {value: cadSkill.cadSkillId , label: cadSkill.cadSkillType}
            });
            skills.riggingSpecialities = skills.riggingSpecialities?.map((specialty: any) => {
                return {value: specialty.riggingSpecialityId , label: specialty.riggingSpecialityType}
            });
            skills.automations = skills.automations?.map((automation: any) => {
                return {value: automation.automationId , label: automation.automationType}
            });
            skills.hoistInspections = skills.hoistInspections?.map((inspection: any) => {
                return {value: inspection.hoistInspectionId , label: inspection.hoistInspectionType}
            });
            skills.drivingLicenses = skills.drivingLicenses?.map((license: any) => {
                return {value: license.drivingLicenseId , label: license.drivingLicenseType}
            });
            skills.languagesSpoken = skills.languagesSpoken?.map((language: any) => {
                return {value: language.languageId , label: language.languageSpoken}
            });
            
            runInAction(() => {
                this.skills = skills;
                this.loadingSkills = false;
            })
        } catch (error) {
            runInAction(() => this.loadingSkills = false);
        }
    }

    updateSkills = async (skills: Partial<Skill>) => {
        this.loading = true;
        try {
            let copiedSkills = JSON.parse(JSON.stringify(skills));
            copiedSkills.yearsOfExperience = copiedSkills.yearsOfExperience?.value;

            copiedSkills.riggingCertificates = copiedSkills.riggingCertificates?.map((certificate: SelectOption) => {
                return {riggingCertificateId: certificate?.value};
               });
               copiedSkills.languagesSpoken = copiedSkills.languagesSpoken?.map((lang: SelectOption) => {
             return {languageId: lang?.value};
            });
            copiedSkills.drivingLicenses = copiedSkills.drivingLicenses?.map((license: SelectOption) => {
                return {drivingLicenseId: license?.value};
               });

               copiedSkills.abseilingCertificates = copiedSkills.abseilingCertificates?.map((certificate: SelectOption) => {
            return {abseilingCertificateId: certificate?.value};
            });

            copiedSkills.mewpCertificates = copiedSkills.mewpCertificates?.map((certificate: SelectOption) => {
            return {mewpCertificateId: certificate?.value};
            });

            copiedSkills.riggingSpecialities = copiedSkills.riggingSpecialities?.map((speciality: SelectOption) => {
            return {riggingSpecialityId: speciality?.value};
            });

            copiedSkills.automations = copiedSkills.automations?.map((automation: SelectOption) => {
            return {automationId: automation?.value};
            });

            copiedSkills.cad = copiedSkills.cad?.map((cadSkill: SelectOption) => {
            return {cadSkillId: cadSkill?.value};
            });

            copiedSkills.hoistInspections = copiedSkills.hoistInspections?.map((hoistInspection: SelectOption) => {
            return {hoistInspectionId: hoistInspection?.value};
            });
            console.log("Skills after mapping");
            console.log(copiedSkills);
            await agent.Profiles.updateProfileSkills(copiedSkills);
            runInAction(() => {
                this.skills = {...this.skills, ...skills as Skill};
                this.loading = false;
                toast.success('Skills updated');
            })
        } catch (error) {
            runInAction(() =>{
                this.loading = false;
                toast.error('Skills could not be updated');
            });
        }
    }

}