import { observer } from 'mobx-react-lite';
import React from 'react'
import { Link } from 'react-router-dom';
import { useStore } from '../../app/stores/store';

export default observer(function ServerError() {

    const {commonStore} = useStore();

  return (
    <div className='relative mx-auto flex flex-col max-w-8xl justify-center sm:px-2 lg:px-8 xl:px-12 align-middle items-center'>
        <h1 className="text-4xl font-bold tracking-tight sm:text-center sm:text-6xl my-6">{commonStore.error?.message}</h1>
        <div className="text-gray-600 align-middle items-center flex-col flex">
            <div>
                {commonStore.error?.details && 
                <div>
                    <h5>Stack trace</h5>
                    <code className='mt-4 text-left'>{commonStore.error.details}</code>
                </div>
                }
            </div>
            <Link to='/' className="my-6 inline-block rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700">
                Back to Homepage
            </Link>
        </div>
  </div>
  )
}) 
