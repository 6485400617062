import { Spinner } from 'flowbite-react';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { LoadingComponent } from '../layout/LoadingComponent';
import { useStore } from '../stores/store';
import * as Yup from 'yup';
import { MyTextInput } from '../common/form/MyTextInput';
import { Dialog, Transition } from '@headlessui/react';
import Select from 'react-select'
import HelperText from 'flowbite-react/lib/esm/components/FormControls/HelperText';
import { countryList, riggingCertificates, abseilingCertificates, supportedDrivingLicenses, languages, yearsOfExperienceList, hoistInspections, cad, automations, riggingSpecialities, mewpCertificates } from '../common/constantData';

export default observer(function UserProfilePage() {
  
  const {nickname} = useParams<{nickname: string}>();
  const {profileStore, userStore} = useStore();
  const {loadingProfile, loadingRates, loadingSkills, loadRates, loadSkills, loadProfile, profile, rates, skills} = profileStore;
  let [isOpen, setIsOpen] = useState(false)
  const [countryError, setCountryError] = useState(false);

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  function deleteAccount() {
    setIsOpen(false);
    userStore.deleteAccount(userStore.user!);
  }

  useEffect(() => {
    loadProfile(nickname);
    loadRates(nickname);
    loadSkills(nickname);
  }, [loadProfile, loadRates, loadSkills, nickname])

  const validationSchema = Yup.object({
    // email: Yup.string().required("Email is required").email("Email is incorrect"),
    nickname: Yup.string().required('Nickname is required'),
    streetAddress: Yup.string().required('Street address is required'),
    postcode: Yup.string().required('Postcode is required'),
    country: Yup.object().required('Country is required'),
    city: Yup.string().required('City is required'),
    airport: Yup.string().nullable(),
    phoneNumber: Yup.string().required('Phone number is required'),
    secondaryPhoneNumber: Yup.string().nullable(),
    emergencyPersonName: Yup.string().nullable(),
    emergencyPersonPhone: Yup.string().nullable(),
})

const ratesValidationSchema = Yup.object({
  rateAsCrewLeader: Yup.string().nullable(),
  rateAsRigger: Yup.string().nullable()
})

  return (
<>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    Delete Account
                  </Dialog.Title>
                  <div className="mt-2 text-center">
                    <p className="mb-1 mt-3">
                      This will permanently delete your account.
                      
                    </p>
                    <p className="mb-1">
                    All of your data will be permanently removed.
                    </p>
                    <p>
                    This action cannot be undone.
                    </p>
                  </div>

                  <div className="mt-6 flex justify-between">
                    <button className="inline-flex justify-center items-center align-middle rounded-md border border-transparent bg-indigo-600 py-2 px-6 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={() => setIsOpen(false)}>Cancel</button>
                    <button className="inline-flex justify-center items-center align-middle rounded-md border border-transparent bg-red-600 py-2 px-6 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" onClick={() => deleteAccount()}>Delete</button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
        <div className="mb-6">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">My Profile</h1>
          <p className="mt-1 text-sm text-gray-400">Update your personal details, rates and skills</p>
        </div>


        <div className="md:grid md:grid-cols-2 md:gap-6 mb-6">  
          <div className="mt-5 md:col-span-2 md:mt-0">
            <h3 className='text-2xl font-bold tracking-tight text-gray-900 mb-3'>Personal details</h3>
            <div className="shadow sm:rounded-md">
              <div className="bg-white sm:rounded-md">
              {loadingProfile ? (<LoadingComponent content="Loading profile" />) : (
                  <Formik
                  validationSchema={validationSchema}
                  initialValues={{
                    nickname: profile?.nickname,
                    country: {'value': profile?.country,'label': profile?.country},
                    personalLiabilityInsurance: {'value': profile?.personalLiabilityInsurance,'label': profile?.personalLiabilityInsurance},
                    streetAddress: profile?.streetAddress,
                    postcode: profile?.postcode,
                    city: profile?.city,
                    airport: profile?.airport,
                    phoneNumber: profile?.phoneNumber,
                    secondaryPhoneNumber: profile?.secondaryPhoneNumber == null ? '' : profile?.secondaryPhoneNumber,
                    emergencyContactName: profile?.emergencyContactName == null ? '' : profile?.emergencyContactName,
                    emergencyContactPhoneNumber: profile?.emergencyContactPhoneNumber == null ? '' : profile?.emergencyContactPhoneNumber,
                    riggingCertificates: null,
                    abseilingCertificates: null,
                    mewpCertificates: null,
                    riggingSpecialities: null,
                    automations: null,
                    cad: null,
                    hoistInspections: null,
                    languagesSpoken: null,
                    drivingLicenses: null,
                    yearsOfExperience: null
                  }}
              onSubmit={(values) => profileStore.updateProfile(values)}
                  >
              {({ values, handleSubmit, setFieldValue, isSubmitting, errors, isValid, dirty }) => (
                  <Form className="space-y-6" autoComplete='off' onSubmit={handleSubmit}>
                      <div className="grid grid-cols-6 gap-6 px-4 py-5 sm:p-6">
                        <MyTextInput className="col-span-12 sm:col-span-6" placeholder={'Nickname'} name={'nickname'} type={'text'} required={true} label={'Nickname'}  />
                        <MyTextInput className="col-span-12 sm:col-span-3" placeholder={'Street address'} name={'streetAddress'} type={'text'} required={true} label={'Your street address'} />
                        <MyTextInput className="col-span-6 sm:col-span-1" placeholder={'Postcode'} name={'postcode'} type={'text'} required={true} label={'Your postcode'} />
                        <MyTextInput className="col-span-6 sm:col-span-2" placeholder={'City'} name={'city'} type={'text'} required={true} label={'Your city of residence'} />
                        
                        {/* <MyTextInput className="col-span-6 sm:col-span-3" placeholder={'Email address'} name={'email'} type={'email'} required={true} label={'Email'}  /> */}
                        <div className="col-span-6 sm:col-span-2">
                          <div className="mb-2">
                            <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Your country of residence<span className='text-red-700 ml-1'>*</span></label>
                          </div>
                          <Select
                            className='react-select-container mb-2 z-20'
                            classNamePrefix="react-select"
                            onFocus={()=>setCountryError(false)}
                            onBlur={()=>{ if(values.country.value === ''){setCountryError(true)} }}
                            isSearchable={true}
                            options={countryList}
                            required
                            value={values.country}
                            onChange={(value) => setFieldValue("country", value)} />
                          {countryError && <HelperText color='failure' className='-translate-y-3 mt-0 text-center p-2 pt-2 mb-2 text-sm text-red-700 bg-red-100 rounded-b-lg dark:bg-red-200 dark:text-red-800'>Country is required</HelperText>}
                        </div>
                        
                        

                        <MyTextInput className="col-span-12 sm:col-span-2" placeholder={'Airport'} name={'airport'} type={'text'} required={false} label={'Your preferred airport'}  />
                      
                        <MyTextInput className="col-span-12 sm:col-span-3" placeholder={'Phone number'} name={'phoneNumber'} type={'text'} required={true} label={'Your phone number'}  />
                        <MyTextInput className="col-span-12 sm:col-span-3" placeholder={'Secondary phone number'} name={'secondaryPhoneNumber'} type={'text'} required={false} label={'Your secondary phone number'}  />
                        <MyTextInput className="col-span-12 sm:col-span-3" placeholder={'Emergency person name'} name={'emergencyContactName'} type={'text'} required={false} label={'Your emergency person contact name'}  />
                        <MyTextInput className="col-span-12 sm:col-span-3" placeholder={'Emergency person phone number'} name={'emergencyContactPhoneNumber'} type={'text'} required={false} label={'Your emergency person contact phone number'}  />
                        
                        <div className="col-span-6 sm:col-span-3">
                          <div className="mb-2">
                            <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Do you currently have personal liability insurance</label>
                          </div>
                          <Select className='react-select-container mb-2' classNamePrefix="react-select" isSearchable={false} options={[
                          { value: 'Yes', label: 'Yes' },
                          { value: 'No', label: 'No' }
                          ]} value={values.personalLiabilityInsurance} onChange={(value) => setFieldValue("personalLiabilityInsurance", value)} />
                        </div>
                      </div>
                    <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                          <button
                            disabled={!isValid || isSubmitting}
                            type="submit"
                            className="inline-flex justify-center items-center align-middle rounded-md disabled:opacity-50 border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            {isSubmitting ? (<Fragment><span className='mr-2'>Updating</span><Spinner aria-label="small spinner" size="sm" color="purple" /></Fragment>) : ("Update") }
                          </button>
                    </div>
                </Form>
              )}
          </Formik>
              )}
              </div>
            </div>
          </div>
        </div>

        <div className="md:grid md:grid-cols-2 md:gap-6 mb-6">
              <div className="mt-5 md:col-span-2 md:mt-0">
                <h3 className='text-2xl font-bold tracking-tight text-gray-900 mb-3'>Rates</h3>
                <div className="shadow sm:rounded-md">
                          <div className="bg-white sm:rounded-md">
                    {loadingRates ? (<LoadingComponent content="Loading rates..." />) : (
                  <Formik
                  validationSchema={ratesValidationSchema}
                  initialValues={{
                    rateAsCrewLeader: rates?.rateAsCrewLeader == null ? '' : rates?.rateAsCrewLeader,
                    rateAsRigger: rates?.rateAsRigger == null ? '' : rates?.rateAsRigger
                  }}
              onSubmit={(values) => profileStore.updateRates(values)}
                  >
              {({ values, handleSubmit, setFieldValue, isSubmitting, errors, isValid, dirty }) => (
                <Form className="space-y-6" autoComplete='off' onSubmit={handleSubmit}>
                      <div className="grid grid-cols-6 gap-6 px-4 py-5 sm:p-6">
                        <MyTextInput className="col-span-12" placeholder={'Rate as crew leader'} name={'rateAsCrewLeader'} type={'text'} required={false} label={'Your expected daily rate as crew leader (10 hours)'} />
                        <MyTextInput className="col-span-12" placeholder={'Rate as rigger'} name={'rateAsRigger'} type={'text'} required={false} label={'Your expected daily rate as rigger (10 hours)'} />
                      </div>
                    <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                      <button
                        disabled={!isValid || isSubmitting}
                        type="submit"
                        className="inline-flex justify-center items-center align-middle rounded-md disabled:opacity-50 border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {isSubmitting ? (<Fragment><span className='mr-2'>Updating</span><Spinner aria-label="small spinner" size="sm" color="purple" /></Fragment>) : ("Update") }
                      </button>
                    </div>
                </Form>
              )}
          </Formik>
                    )}
                    </div>
                  </div>
              </div>
        </div>

        <div className="md:grid md:grid-cols-2 md:gap-6 mb-6">
          <div className="mt-5 md:col-span-2 md:mt-0">
            <h3 className='text-2xl font-bold tracking-tight text-gray-900 mb-3'>Skills</h3>
            <div className="shadow sm:rounded-md">
              <div className="bg-white sm:rounded-md">
              {loadingSkills ? (<LoadingComponent content="Loading skills..." />) : (
                  <Formik
                  initialValues={{
                    riggingCertificates: skills?.riggingCertificates,
                    abseilingCertificates: skills?.abseilingCertificates,
                    mewpCertificates: skills?.mewpCertificates,
                    riggingSpecialities: skills?.riggingSpecialities,
                    automations: skills?.automations,
                    cad: skills?.cad,
                    hoistInspections: skills?.hoistInspections,
                    languagesSpoken: skills?.languagesSpoken,
                    drivingLicenses: skills?.drivingLicenses,
                    yearsOfExperience: skills?.yearsOfExperience === null ? {'value': '','label': 'Select...'} : {'value': skills?.yearsOfExperience,'label': skills?.yearsOfExperience}
                  }}
                  onSubmit={(values) => profileStore.updateSkills(values)}
                  >
              {({ values, handleSubmit, setFieldValue, isSubmitting, errors, isValid, dirty }) => (
                <Form className="space-y-6" autoComplete='off' onSubmit={handleSubmit}>
                      <div className="grid grid-cols-6 gap-6 px-4 py-5 sm:p-6">
           

                      <div className="col-span-6 sm:col-span-3">
                      <div className="mb-2 block">
                            <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Your rigging certificates</label>
                            </div>
                            <Select className='react-select-container mb-2' classNamePrefix="react-select" isSearchable={false} options={riggingCertificates} isMulti required value={values.riggingCertificates} onChange={(value) => setFieldValue("riggingCertificates", value)} />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                      <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Your abseiling certificates</label>
                            </div>
                          
                            <Select className='react-select-container mb-2'
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    options={abseilingCertificates}
                                    isMulti
                                    required
                                    value={values.abseilingCertificates}
                                    onChange={(value) => setFieldValue("abseilingCertificates", value)} />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                      <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Your MEWP certificates</label>
                            </div>
                          
                            <Select className='react-select-container mb-2'
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    options={mewpCertificates}
                                    isMulti
                                    required
                                    value={values.mewpCertificates}
                                    onChange={(value) => setFieldValue("mewpCertificates", value)} />
                      </div>                    
                      <div className="col-span-6 sm:col-span-3">
                      <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Your rigging specialities</label>
                            </div>
                          
                            <Select className='react-select-container mb-2'
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    options={riggingSpecialities}
                                    isMulti
                                    required
                                    value={values.riggingSpecialities}
                                    onChange={(value) => setFieldValue("riggingSpecialities", value)} />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                      <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Automations</label>
                            </div>
                          
                            <Select className='react-select-container mb-2'
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    options={automations}
                                    isMulti
                                    required
                                    value={values.automations}
                                    onChange={(value) => setFieldValue("automations", value)} />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                      <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">CAD</label>
                            </div>
                          
                            <Select className='react-select-container mb-2'
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    options={cad}
                                    isMulti
                                    required
                                    value={values.cad}
                                    onChange={(value) => setFieldValue("cad", value)} />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                      <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Hoist inspections</label>
                            </div>
                          
                            <Select className='react-select-container mb-2'
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    options={hoistInspections}
                                    isMulti
                                    required
                                    value={values.hoistInspections}
                                    onChange={(value) => setFieldValue("hoistInspections", value)} />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                      <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Languages spoken</label>
                            </div>
                          
                            <Select className='react-select-container mb-2' classNamePrefix="react-select" isSearchable={false} options={languages} isMulti required value={values.languagesSpoken} onChange={(value) => setFieldValue("languagesSpoken", value)} />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                      <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Driving licenses</label>
                            </div>
                          
                            <Select className='react-select-container mb-2' classNamePrefix="react-select" isSearchable={false} options={supportedDrivingLicenses} isMulti required value={values.drivingLicenses} onChange={(value) => setFieldValue("drivingLicenses", value)} />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                      <div className="mb-2 block">
                              <label className="text-sm font-medium text-gray-900 dark:text-gray-300">Numer of years of experience in rigging</label>
                            </div>
                            <Select className='react-select-container mb-2' classNamePrefix="react-select" isSearchable={false} options={yearsOfExperienceList} value={values.yearsOfExperience} onChange={(value) => setFieldValue("yearsOfExperience", value)} />
                      </div>
                      </div>
                    <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                      <button
                        disabled={!isValid || isSubmitting}
                        type="submit"
                        className="inline-flex justify-center items-center align-middle rounded-md disabled:opacity-50 border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {isSubmitting ? (<Fragment><span className='mr-2'>Updating</span><Spinner aria-label="small spinner" size="sm" color="purple" /></Fragment>) : ("Update") }
                      </button>
                    </div>
                </Form>
              )}
          </Formik>
                    )}
              </div>
            </div>
          </div>
        </div>

        <div className="md:grid md:grid-cols-2 md:gap-6 mb-6">
          {loadingProfile === false &&
                      <div className="mt-5 md:col-span-2 md:mt-0">
                            <h3 className='text-2xl font-bold tracking-tight text-gray-900 mb-3'>Danger zone</h3>
                        <div className="shadow sm:rounded-md">
                          <div className="bg-white px-4 py-5 sm:p-6 flex justify-end items-center sm:rounded-md">
                            <span className='mr-4 flex align-middle items-center'>Click if you wish to delete your account</span>
                          <button
                                  type='button'
                                  onClick={openModal}
                                  className="inline-flex justify-center items-center align-middle rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                >
                                  Delete Account
                                </button>
                          </div>
                        </div>
              </div>
          }
        </div>
      </div>
    </>
  )
})