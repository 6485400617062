import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';
import { HomePage } from '../pages/HomePage';
import { SignUpPage } from '../pages/SignUpPage';
import NavBar from './NavBar';
import { useStore } from '../stores/store';
import { LoadingComponent } from './LoadingComponent';
import TestErrors from '../../features/errors/TestError';
import { ToastContainer } from 'react-toastify';
import { NotFound } from '../../features/errors/NotFound';
import ServerError from '../../features/errors/ServerError';
import SignInPage from '../pages/SignInPage';
import UserProfilePage from '../pages/UserProfilePage';
import { Forbidden } from '../../features/errors/Fobidden';
import PrivateRoute from './PrivateRoute';
import RequestPasswordResetPage from '../pages/RequestPasswordResetPage';
import PasswordResetPage from '../pages/PasswordResetPage';
import ContactPage from '../pages/ContactPage';
import Footer from './Footer';
import { PrivacyPolicy } from '../pages/PrivacyPolicy';


function App() {

  const {commonStore, userStore} = useStore();

  useEffect(() => {
    if(commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore])
  
  if(!commonStore.appLoaded){
    return <LoadingComponent />
  }

  return (
    <>
    <ToastContainer position='top-center' hideProgressBar />
      <NavBar />
      <div className='mx-auto max-w-7xl justify-center sm:px-2 lg:px-8 xl:px-12'>
        <main>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <PrivateRoute path='/profile/:nickname' component={UserProfilePage} />
            <Route path='/privacypolicy' component={PrivacyPolicy} />
            <Route path='/sign-up' component={SignUpPage} />
            <Route path='/sign-in' component={SignInPage} />
            <Route path='/requestPasswordReset' component={RequestPasswordResetPage} />
            <Route path='/passwordReset' component={PasswordResetPage} />
            <Route path='/contact' component={ContactPage} />
            <Route path='/errors' component={TestErrors} />
            <Route path='/server-error' component={ServerError} />
            <Route path='/forbidden' component={Forbidden} />
            <Route component={NotFound} />
          </Switch>
        </main>
      </div>
      <Footer/>
    </>
  );
}

export default observer(App);
