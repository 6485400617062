import { Fragment } from 'react'
import { Disclosure, Listbox, Menu, Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, UserCircleIcon, ChevronDownIcon, ArrowPathIcon, BookmarkSquareIcon, CalendarIcon, ChartBarIcon, CursorArrowRaysIcon, LifebuoyIcon, PhoneIcon, PlayIcon, ShieldCheckIcon, Squares2X2Icon, HomeIcon, HomeModernIcon } from '@heroicons/react/24/outline'
import { Link, NavLink } from 'react-router-dom'
import { useStore } from '../stores/store'
import { observer } from 'mobx-react-lite'

const navigation = [
  { name: 'Home', href: '/' },
  // { name: 'Gigs', href: '/gigs' },
  // { name: 'Calendar', href: '/calendar' },
  // { name: 'Errors', href: '/errors' },
]

const solutions = [
  {
    name: 'Home',
    description: 'Stage Riggers Homepage',
    href: '/',
    icon: HomeIcon,
  }
]
const callsToAction = [
  { name: 'Watch Demo', href: '#', icon: PlayIcon },
  { name: 'Contact Sales', href: '#', icon: PhoneIcon },
]

function classNames(...classes : any) {
  return classes.filter(Boolean).join(' ')
}

function NavBar() {

  const {userStore: {user, logout, isLoggedIn}} = useStore();


  return (
    <Popover className="relative bg-white shadow-sm">
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between border-b-2 py-5 border-gray-100 md:justify-start md:space-x-10">
          <div className="flex justify-start">
                     <Link to='/'>
                     <span className="sr-only">Stage Riggers</span>
                 <div className="flex flex-shrink-0 items-center">
                   <img src='/assets/logo.svg' className="block h-12 w-auto lg:hidden bg-white rounded-lg p-1" alt='Stage Riggers' />
                   <img src='/assets/logo.svg' className="hidden h-12 w-auto lg:block bg-white rounded-lg p-1" alt='Stage Riggers' />
                 </div>
               </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex">

            <NavLink to='/' className="text-base font-medium text-gray-500 hover:text-gray-900">
              Home
            </NavLink>

          </Popover.Group>
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
                       {isLoggedIn ? (
                  <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex rounded-full p-1 text-gray-500 hover:text-gray-900 focus:outline-none">
                      <span className="sr-only">Open user menu</span>
                      <UserCircleIcon className="h-8 w-8" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {/* <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Your Profile
                          </a>
                        )}
                      </Menu.Item> */}
                      <Menu.Item>
                      {({ active }) => (
                          <span className='block px-4 py-2 text-sm text-gray-700 border-b-3 border-gray-400 border-b border-b-gray-200'>{user?.nickname}</span>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink to={`/profile/${user?.nickname}`} exact
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            My profile
                          </NavLink>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a onClick={logout}
                            className={classNames(active ? 'bg-gray-100' : '', ' cursor-pointer block px-4 py-2 text-sm text-gray-700')}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
                ) : (
                <Fragment>
                      <NavLink to='/sign-in' className="whitespace-nowrap border-2 rounded-md border-indigo-600 px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-900">
              Sign in
            </NavLink>
            <NavLink to='/sign-up'
              className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
            >
              Sign up
            </NavLink>
                </Fragment>
                )
                }
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="z-50 absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-4">
              <div className="flex items-center justify-between">
                <div className='flex items-center'>
                  <img
                    className="h-10 w-auto"
                    src='/assets/logo.svg'
                    alt="Stage Riggers"
                  />
                  {isLoggedIn && <span className='px-4 ml-4 py-1 text-sm text-gray-700 border-l border-gray-200'>{user?.nickname}</span>}
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="space-y-6 py-4 px-2">
            {isLoggedIn ? (
                  <Popover.Group className="relative border-0">
                      {solutions.map((item) => (
                <Popover.Button as={NavLink} to={item.href} key={item.name}
                className="block w-full text-left px-3 py-3 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none cursor-pointer rounded-md">
                  {item.name}
                </Popover.Button>
                  ))}
                      <Popover.Button as={NavLink}
                      to={`/profile/${user?.nickname}`} exact
                      className='block w-full text-left px-3 py-3 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none cursor-pointer rounded-md'>
                        My profile
                      </Popover.Button>
                      <Popover.Button onClick={logout} className='block w-full text-left px-3 py-3 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none cursor-pointer rounded-md'>
                        Sign out
                      </Popover.Button>
                </Popover.Group>
                ) : (
                <Fragment>
              <Popover.Group className="relative border-0">
              {solutions.map((item) => (
                <Popover.Button as={NavLink}
                to={item.href}
                      key={item.name}
                      className="block w-full text-left px-3 py-3 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none cursor-pointer rounded-md">
                  {item.name}
                </Popover.Button>
                  ))}
              </Popover.Group>
                      <Popover.Button as={NavLink} to='/sign-up'
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                >
                  Sign up
                </Popover.Button>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Already have an account?{' '}
                  <Popover.Button as={NavLink} to='/sign-in' className="text-indigo-600 hover:text-indigo-500">
                    Sign in
                  </Popover.Button>
                </p>
                </Fragment>
                )
                }
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
    // <Disclosure as="nav" className="bg-gray-800">
    //   {({ open }) => (
    //     <>
    //       <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
    //         <div className="relative flex h-16 items-center justify-between">
    //           <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
    //             {/* Mobile menu button*/}
    //             <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
    //               <span className="sr-only">Open main menu</span>
    //               {open ? (
    //                 <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
    //               ) : (
    //                 <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
    //               )}
    //             </Disclosure.Button>
    //           </div>
    //           <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
    //           <Link to='/'>
    //             <div className="flex flex-shrink-0 items-center">
    //               <img src='/assets/logo.svg' className="block h-10 w-auto lg:hidden bg-white rounded-lg p-1" alt='Stage Riggers' />
    //               <img src='/assets/logo.svg' className="hidden h-10 w-auto lg:block bg-white rounded-lg p-1" alt='Stage Riggers' />
    //             </div>
    //           </Link>
    //             <div className="hidden sm:ml-6 sm:block">
    //               <div className="flex space-x-4">
    //                 {navigation.map((item) => (
    //                   <NavLink to={item.href} exact 
    //                     key={item.name}
    //                     className={isActive =>
    //                       "px-3 py-2 rounded-md text-sm font-medium " + (!isActive ? " text-gray-300 hover:bg-gray-700 hover:text-white"
    //                       :
    //                       "bg-gray-900 text-white")
    //                     }
    //                     >
    //                     {item.name}
    //                   </NavLink>
    //                 ))}
    //               </div>
    //             </div>
    //           </div>
    //           <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
    //             {/* <button
    //               type="button"
    //               className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none">
    //               <span className="sr-only">View notifications</span>
    //               <BellIcon className="h-6 w-6" aria-hidden="true" />
    //             </button> */}

    //             {/* Profile dropdown */}
    //             {isLoggedIn ? (
    //               <Menu as="div" className="relative ml-3">
    //               <div>
    //                 <Menu.Button className="flex rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none">
    //                   <span className="sr-only">Open user menu</span>
    //                   <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
    //                 </Menu.Button>
    //               </div>
    //               <Transition
    //                 as={Fragment}
    //                 enter="transition ease-out duration-100"
    //                 enterFrom="transform opacity-0 scale-95"
    //                 enterTo="transform opacity-100 scale-100"
    //                 leave="transition ease-in duration-75"
    //                 leaveFrom="transform opacity-100 scale-100"
    //                 leaveTo="transform opacity-0 scale-95"
    //               >
    //                 <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
    //                   {/* <Menu.Item>
    //                     {({ active }) => (
    //                       <a
    //                         href="#"
    //                         className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
    //                       >
    //                         Your Profile
    //                       </a>
    //                     )}
    //                   </Menu.Item> */}
    //                   <Menu.Item>
    //                   {({ active }) => (
    //                       <span className='block px-4 py-2 text-sm text-gray-700 border-b-3 border-gray-400 border-b border-b-gray-200'>{user?.nickname}</span>
    //                     )}
    //                   </Menu.Item>
    //                   <Menu.Item>
    //                     {({ active }) => (
    //                       <NavLink to={`/profile/${user?.nickname}`} exact
    //                         className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
    //                       >
    //                         My profile
    //                       </NavLink>
    //                     )}
    //                   </Menu.Item>
    //                   <Menu.Item>
    //                     {({ active }) => (
    //                       <a onClick={logout}
    //                         className={classNames(active ? 'bg-gray-100' : '', ' cursor-pointer block px-4 py-2 text-sm text-gray-700')}
    //                       >
    //                         Sign out
    //                       </a>
    //                     )}
    //                   </Menu.Item>
    //                 </Menu.Items>
    //               </Transition>
    //             </Menu>
    //             ) : (
    //             <Fragment>
    //                   <NavLink to='/sign-in' exact className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
    //                 Sign in
    //               </NavLink>
    //               <NavLink to='/sign-up' exact className='ml-3'>
    //                 <button className="border text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
    //                   Sign up
    //                 </button>
    //               </NavLink>
    //             </Fragment>
    //             )
    //             }
    //           </div>
    //         </div>
    //       </div>

    //       <Disclosure.Panel className="sm:hidden">
    //         <div className="space-y-1 px-2 pt-2 pb-3">
    //           {navigation.map((item) => (
    //             <NavLink to={item.href} exact
    //               key={item.name}
    //               className={(isActive: any) =>
    //                 "block px-3 py-2 rounded-md text-base font-medium " + (!isActive ? " text-gray-300 hover:bg-gray-700 hover:text-white"
    //                 :
    //                 "bg-gray-900 text-white")
    //               }
    //               >
    //               {item.name}
    //             </NavLink>
    //           ))}
    //         </div>
    //       </Disclosure.Panel>
    //     </>
    //   )}
    // </Disclosure>
  )
}
export default observer(NavBar);