import { observer } from 'mobx-react-lite';
import React from 'react'
import { Link } from 'react-router-dom';
import { useStore } from '../stores/store';

function HeroSectionComponent() {
    //const {gigStore, userStore} = useStore();
    const { userStore } = useStore();


    // useEffect(()=>{
    //   gigStore.loadGigs();
    // }, [gigStore])

  return (
    <div className="py-16 sm:py-26 lg:py-28">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center">
          <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
            A better way to book talent
          </p>
          <p className="mx-auto mt-3 max-w-2xl text-lg leading-8 text-gray-600 py-5">
            Behind every great performance, there is a team of dedicated stage rigging professionals working tirelessly to make sure the show goes off without a hitch.
          </p>
          <p>
          {userStore.isLoggedIn ? (
                          <Link to="contact" className='inline-block'>
                            <button className='drop-shadow-xl relative flex my-5 justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
                              I need riggers ASAP &nbsp;|&nbsp; Send request
                            </button>
                          </Link>
                      ) : (
                        <>
                          <Link to="sign-up" className='inline-block'>
                              <button className='drop-shadow-xl mr-4 relative flex my-5 justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
                                I'm a rigger &nbsp;|&nbsp; Sign up
                              </button>
                          </Link>
                          <Link to="contact" className='inline-block'>
                            <button className='drop-shadow-xl relative flex my-5 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 py-2 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
                              I need riggers ASAP &nbsp;|&nbsp; Send request
                            </button>
                          </Link>
                        </>
                      )}
          </p>
        </div>
      </div>
    </div>
  )
}

export default observer(HeroSectionComponent);
