export const nationalityList = [
    {'value': 'Afghan', 'label': 'Afghan'},
{'value': 'Albanian', 'label': 'Albanian'},
{'value': 'Algerian', 'label': 'Algerian'},
{'value': 'American', 'label': 'American'},
{'value': 'Andorran', 'label': 'Andorran'},
{'value': 'Angolan', 'label': 'Angolan'},
{'value': 'Antiguans', 'label': 'Antiguans'},
{'value': 'Argentinean', 'label': 'Argentinean'},
{'value': 'Armenian', 'label': 'Armenian'},
{'value': 'Australian', 'label': 'Australian'},
{'value': 'Austrian', 'label': 'Austrian'},
{'value': 'Azerbaijani', 'label': 'Azerbaijani'},
{'value': 'Bahamian', 'label': 'Bahamian'},
{'value': 'Bahraini', 'label': 'Bahraini'},
{'value': 'Bangladeshi', 'label': 'Bangladeshi'},
{'value': 'Barbadian', 'label': 'Barbadian'},
{'value': 'Barbudans', 'label': 'Barbudans'},
{'value': 'Batswana', 'label': 'Batswana'},
{'value': 'Belarusian', 'label': 'Belarusian'},
{'value': 'Belgian', 'label': 'Belgian'},
{'value': 'Belizean', 'label': 'Belizean'},
{'value': 'Beninese', 'label': 'Beninese'},
{'value': 'Bhutanese', 'label': 'Bhutanese'},
{'value': 'Bolivian', 'label': 'Bolivian'},
{'value': 'Bosnian', 'label': 'Bosnian'},
{'value': 'Brazilian', 'label': 'Brazilian'},
{'value': 'British', 'label': 'British'},
{'value': 'Bruneian', 'label': 'Bruneian'},
{'value': 'Bulgarian', 'label': 'Bulgarian'},
{'value': 'Burkinabe', 'label': 'Burkinabe'},
{'value': 'Burmese', 'label': 'Burmese'},
{'value': 'Burundian', 'label': 'Burundian'},
{'value': 'Cambodian', 'label': 'Cambodian'},
{'value': 'Cameroonian', 'label': 'Cameroonian'},
{'value': 'Canadian', 'label': 'Canadian'},
{'value': 'Cape', 'label': 'Cape Verdean'},
{'value': 'Central African', 'label': 'Central African'},
{'value': 'Chadian', 'label': 'Chadian'},
{'value': 'Chilean', 'label': 'Chilean'},
{'value': 'Chinese', 'label': 'Chinese'},
{'value': 'Colombian', 'label': 'Colombian'},
{'value': 'Comoran', 'label': 'Comoran'},
{'value': 'Congolese', 'label': 'Congolese'},
{'value': 'Costa Rican', 'label': 'Costa Rican'},
{'value': 'Croatian', 'label': 'Croatian'},
{'value': 'Cuban', 'label': 'Cuban'},
{'value': 'Cypriot', 'label': 'Cypriot'},
{'value': 'Czech', 'label': 'Czech'},
{'value': 'Danish', 'label': 'Danish'},
{'value': 'Djibouti', 'label': 'Djibouti'},
{'value': 'Dominican', 'label': 'Dominican'},
{'value': 'Dutch', 'label': 'Dutch'},
{'value': 'East', 'label': 'East Timorese'},
{'value': 'Ecuadorean', 'label': 'Ecuadorean'},
{'value': 'Egyptian', 'label': 'Egyptian'},
{'value': 'Emirian', 'label': 'Emirian'},
{'value': 'Equatorial Guinean', 'label': 'Equatorial Guinean'},
{'value': 'Eritrean', 'label': 'Eritrean'},
{'value': 'Estonian', 'label': 'Estonian'},
{'value': 'Ethiopian', 'label': 'Ethiopian'},
{'value': 'Fijian', 'label': 'Fijian'},
{'value': 'Filipino', 'label': 'Filipino'},
{'value': 'Finnish', 'label': 'Finnish'},
{'value': 'French', 'label': 'French'},
{'value': 'Gabonese', 'label': 'Gabonese'},
{'value': 'Gambian', 'label': 'Gambian'},
{'value': 'Georgian', 'label': 'Georgian'},
{'value': 'German', 'label': 'German'},
{'value': 'Ghanaian', 'label': 'Ghanaian'},
{'value': 'Greek', 'label': 'Greek'},
{'value': 'Grenadian', 'label': 'Grenadian'},
{'value': 'Guatemalan', 'label': 'Guatemalan'},
{'value': 'Guinea-Bissauan', 'label': 'Guinea-Bissauan'},
{'value': 'Guinean', 'label': 'Guinean'},
{'value': 'Guyanese', 'label': 'Guyanese'},
{'value': 'Haitian', 'label': 'Haitian'},
{'value': 'Herzegovinian', 'label': 'Herzegovinian'},
{'value': 'Honduran', 'label': 'Honduran'},
{'value': 'Hungarian', 'label': 'Hungarian'},
{'value': 'I-Kiribati', 'label': 'I-Kiribati'},
{'value': 'Icelander', 'label': 'Icelander'},
{'value': 'Indian', 'label': 'Indian'},
{'value': 'Indonesian', 'label': 'Indonesian'},
{'value': 'Iranian', 'label': 'Iranian'},
{'value': 'Iraqi', 'label': 'Iraqi'},
{'value': 'Irish', 'label': 'Irish'},
{'value': 'Israeli', 'label': 'Israeli'},
{'value': 'Italian', 'label': 'Italian'},
{'value': 'Ivorian', 'label': 'Ivorian'},
{'value': 'Jamaican', 'label': 'Jamaican'},
{'value': 'Japanese', 'label': 'Japanese'},
{'value': 'Jordanian', 'label': 'Jordanian'},
{'value': 'Kazakhstani', 'label': 'Kazakhstani'},
{'value': 'Kenyan', 'label': 'Kenyan'},
{'value': 'Kittian and Nevisian', 'label': 'Kittian and Nevisian'},
{'value': 'Kuwaiti', 'label': 'Kuwaiti'},
{'value': 'Kyrgyz', 'label': 'Kyrgyz'},
{'value': 'Laotian', 'label': 'Laotian'},
{'value': 'Latvian', 'label': 'Latvian'},
{'value': 'Lebanese', 'label': 'Lebanese'},
{'value': 'Liberian', 'label': 'Liberian'},
{'value': 'Libyan', 'label': 'Libyan'},
{'value': 'Liechtensteiner', 'label': 'Liechtensteiner'},
{'value': 'Lithuanian', 'label': 'Lithuanian'},
{'value': 'Luxembourger', 'label': 'Luxembourger'},
{'value': 'Macedonian', 'label': 'Macedonian'},
{'value': 'Malagasy', 'label': 'Malagasy'},
{'value': 'Malawian', 'label': 'Malawian'},
{'value': 'Malaysian', 'label': 'Malaysian'},
{'value': 'Maldivan', 'label': 'Maldivan'},
{'value': 'Malian', 'label': 'Malian'},
{'value': 'Maltese', 'label': 'Maltese'},
{'value': 'Marshallese', 'label': 'Marshallese'},
{'value': 'Mauritanian', 'label': 'Mauritanian'},
{'value': 'Mauritian', 'label': 'Mauritian'},
{'value': 'Mexican', 'label': 'Mexican'},
{'value': 'Micronesian', 'label': 'Micronesian'},
{'value': 'Moldovan', 'label': 'Moldovan'},
{'value': 'Monacan', 'label': 'Monacan'},
{'value': 'Mongolian', 'label': 'Mongolian'},
{'value': 'Moroccan', 'label': 'Moroccan'},
{'value': 'Mosotho', 'label': 'Mosotho'},
{'value': 'Motswana', 'label': 'Motswana'},
{'value': 'Mozambican', 'label': 'Mozambican'},
{'value': 'Namibian', 'label': 'Namibian'},
{'value': 'Nauruan', 'label': 'Nauruan'},
{'value': 'Nepalese', 'label': 'Nepalese'},
{'value': 'New', 'label': 'New Zealander'},
{'value': 'Nicaraguan', 'label': 'Nicaraguan'},
{'value': 'Nigerian', 'label': 'Nigerian'},
{'value': 'Nigerien', 'label': 'Nigerien'},
{'value': 'North Korean', 'label': 'North Korean'},
{'value': 'Northern Irish', 'label': 'Northern Irish'},
{'value': 'Norwegian', 'label': 'Norwegian'},
{'value': 'Omani', 'label': 'Omani'},
{'value': 'Pakistani', 'label': 'Pakistani'},
{'value': 'Palauan', 'label': 'Palauan'},
{'value': 'Panamanian', 'label': 'Panamanian'},
{'value': 'Papua New Guinean', 'label': 'Papua New Guinean'},
{'value': 'Paraguayan', 'label': 'Paraguayan'},
{'value': 'Peruvian', 'label': 'Peruvian'},
{'value': 'Polish', 'label': 'Polish'},
{'value': 'Portuguese', 'label': 'Portuguese'},
{'value': 'Qatari', 'label': 'Qatari'},
{'value': 'Romanian', 'label': 'Romanian'},
{'value': 'Russian', 'label': 'Russian'},
{'value': 'Rwandan', 'label': 'Rwandan'},
{'value': 'Saint Lucian', 'label': 'Saint Lucian'},
{'value': 'Salvadoran', 'label': 'Salvadoran'},
{'value': 'Samoan', 'label': 'Samoan'},
{'value': 'San', 'label': 'San Marinese'},
{'value': 'Sao', 'label': 'Sao Tomean'},
{'value': 'Saudi', 'label': 'Saudi'},
{'value': 'Scottish', 'label': 'Scottish'},
{'value': 'Senegalese', 'label': 'Senegalese'},
{'value': 'Serbian', 'label': 'Serbian'},
{'value': 'Seychellois', 'label': 'Seychellois'},
{'value': 'Sierra Leonean', 'label': 'Sierra Leonean' },
{'value': 'Singaporean', 'label': 'Singaporean'},
{'value': 'Slovakian', 'label': 'Slovakian'},
{'value': 'Slovenian', 'label': 'Slovenian'},
{'value': 'Solomon Islander', 'label': 'Solomon Islander' },
{'value': 'Somali', 'label': 'Somali'},
{'value': 'South African', 'label': 'South African' },
{'value': 'South Korean', 'label': 'South Korean' },
{'value': 'Spanish', 'label': 'Spanish'},
{'value': 'Sri', 'label': 'Sri Lankan'},
{'value': 'Sudanese', 'label': 'Sudanese'},
{'value': 'Surinamer', 'label': 'Surinamer'},
{'value': 'Swazi', 'label': 'Swazi'},
{'value': 'Swedish', 'label': 'Swedish'},
{'value': 'Swiss', 'label': 'Swiss'},
{'value': 'Syrian', 'label': 'Syrian'},
{'value': 'Taiwanese', 'label': 'Taiwanese'},
{'value': 'Tajik', 'label': 'Tajik'},
{'value': 'Tanzanian', 'label': 'Tanzanian'},
{'value': 'Thai', 'label': 'Thai'},
{'value': 'Togolese', 'label': 'Togolese'},
{'value': 'Tongan', 'label': 'Tongan'},
{'value': 'Trinidadian or Tobagonian', 'label': 'Trinidadian or Tobagonian' },
{'value': 'Tunisian', 'label': 'Tunisian'},
{'value': 'Turkish', 'label': 'Turkish'},
{'value': 'Tuvaluan', 'label': 'Tuvaluan'},
{'value': 'Ugandan', 'label': 'Ugandan'},
{'value': 'Ukrainian', 'label': 'Ukrainian'},
{'value': 'Uruguayan', 'label': 'Uruguayan'},
{'value': 'Uzbekistani', 'label': 'Uzbekistani'},
{'value': 'Venezuelan', 'label': 'Venezuelan'},
{'value': 'Vietnamese', 'label': 'Vietnamese'},
{'value': 'Welsh', 'label': 'Welsh'},
{'value': 'Yemenite', 'label': 'Yemenite'},
{'value': 'Zambian', 'label': 'Zambian'},
{'value': 'Zimbabwean', 'label': 'Zimbabwean'}
  ]

export const countryList = [
    {'value':'Afghanistan','label':'Afghanistan'},
{'value':'Albania','label':'Albania'},
{'value':'Algeria','label':'Algeria'},
{'value':'Andorra','label':'Andorra'},
{'value':'Angola','label':'Angola'},
{'value':'Antigua and Barbuda','label':'Antigua and Barbuda'},
{'value':'Argentina','label':'Argentina'},
{'value':'Armenia','label':'Armenia'},
{'value':'Australia','label':'Australia'},
{'value':'Austria','label':'Austria'},
{'value':'Azerbaijan','label':'Azerbaijan'},
{'value':'Bahamas','label':'Bahamas'},
{'value':'Bahrain','label':'Bahrain'},
{'value':'Bangladesh','label':'Bangladesh'},
{'value':'Barbados','label':'Barbados'},
{'value':'Belarus','label':'Belarus'},
{'value':'Belgium','label':'Belgium'},
{'value':'Belize','label':'Belize'},
{'value':'Benin','label':'Benin'},
{'value':'Bhutan','label':'Bhutan'},
{'value':'Bolivia (Plurinational State of)','label':'Bolivia (Plurinational State of)'},
{'value':'Bosnia and Herzegovina','label':'Bosnia and Herzegovina'},
{'value':'Botswana','label':'Botswana'},
{'value':'Brazil','label':'Brazil'},
{'value':'Brunei Darussalam','label':'Brunei Darussalam'},
{'value':'Bulgaria','label':'Bulgaria'},
{'value':'Burkina Faso','label':'Burkina Faso'},
{'value':'Burundi','label':'Burundi'},
{'value':'Cabo Verde','label':'Cabo Verde'},
{'value':'Cambodia','label':'Cambodia'},
{'value':'Cameroon','label':'Cameroon'},
{'value':'Canada','label':'Canada'},
{'value':'Central African Republic','label':'Central African Republic'},
{'value':'Chad','label':'Chad'},
{'value':'Chile','label':'Chile'},
{'value':'China','label':'China'},
{'value':'Colombia','label':'Colombia'},
{'value':'Comoros','label':'Comoros'},
{'value':'Congo','label':'Congo'},
{'value':'Congo, Democratic Republic of the','label':'Congo, Democratic Republic of the'},
{'value':'Costa Rica','label':'Costa Rica'},
{'value':"Côte d'Ivoire",'label':"Côte d'Ivoire"},
{'value':'Croatia','label':'Croatia'},
{'value':'Cuba','label':'Cuba'},
{'value':'Cyprus','label':'Cyprus'},
{'value':'Czechia','label':'Czechia'},
{'value':'Denmark','label':'Denmark'},
{'value':'Djibouti','label':'Djibouti'},
{'value':'Dominica','label':'Dominica'},
{'value':'Dominican Republic','label':'Dominican Republic'},
{'value':'Ecuador','label':'Ecuador'},
{'value':'Egypt','label':'Egypt'},
{'value':'El Salvador','label':'El Salvador'},
{'value':'Equatorial Guinea','label':'Equatorial Guinea'},
{'value':'Eritrea','label':'Eritrea'},
{'value':'Estonia','label':'Estonia'},
{'value':'Eswatini','label':'Eswatini'},
{'value':'Ethiopia','label':'Ethiopia'},
{'value':'Fiji','label':'Fiji'},
{'value':'Finland','label':'Finland'},
{'value':'France','label':'France'},
{'value':'Gabon','label':'Gabon'},
{'value':'Gambia','label':'Gambia'},
{'value':'Georgia','label':'Georgia'},
{'value':'Germany','label':'Germany'},
{'value':'Ghana','label':'Ghana'},
{'value':'Greece','label':'Greece'},
{'value':'Grenada','label':'Grenada'},
{'value':'Guatemala','label':'Guatemala'},
{'value':'Guinea','label':'Guinea'},
{'value':'Guinea-Bissau','label':'Guinea-Bissau'},
{'value':'Guyana','label':'Guyana'},
{'value':'Haiti','label':'Haiti'},
{'value':'Honduras','label':'Honduras'},
{'value':'Hungary','label':'Hungary'},
{'value':'Iceland','label':'Iceland'},
{'value':'India','label':'India'},
{'value':'Indonesia','label':'Indonesia'},
{'value':'Iran (Islamic Republic of)','label':'Iran (Islamic Republic of)'},
{'value':'Iraq','label':'Iraq'},
{'value':'Ireland','label':'Ireland'},
{'value':'Israel','label':'Israel'},
{'value':'Italy','label':'Italy'},
{'value':'Jamaica','label':'Jamaica'},
{'value':'Japan','label':'Japan'},
{'value':'Jordan','label':'Jordan'},
{'value':'Kazakhstan','label':'Kazakhstan'},
{'value':'Kenya','label':'Kenya'},
{'value':'Kiribati','label':'Kiribati'},
{'value':"Korea Democratic People's Republic of", 'label':"Korea Democratic People's Republic of"},
{'value':'Korea, Republic of','label':'Korea, Republic of'},
{'value':'Kuwait','label':'Kuwait'},
{'value':'Kyrgyzstan','label':'Kyrgyzstan'},
{'value':"Lao People's Democratic Republic",'label':"Lao People's Democratic Republic"},
{'value':'Latvia','label':'Latvia'},
{'value':'Lebanon','label':'Lebanon'},
{'value':'Lesotho','label':'Lesotho'},
{'value':'Liberia','label':'Liberia'},
{'value':'Libya','label':'Libya'},
{'value':'Liechtenstein','label':'Liechtenstein'},
{'value':'Lithuania','label':'Lithuania'},
{'value':'Luxembourg','label':'Luxembourg'},
{'value':'Madagascar','label':'Madagascar'},
{'value':'Malawi','label':'Malawi'},
{'value':'Malaysia','label':'Malaysia'},
{'value':'Maldives','label':'Maldives'},
{'value':'Mali','label':'Mali'},
{'value':'Malta','label':'Malta'},
{'value':'Marshall Islands','label':'Marshall Islands'},
{'value':'Mauritania','label':'Mauritania'},
{'value':'Mauritius','label':'Mauritius'},
{'value':'Mexico','label':'Mexico'},
{'value':'Micronesia Federated States of','label':'Micronesia Federated States of'},
{'value':'Moldova, Republic of','label':'Moldova, Republic of'},
{'value':'Monaco','label':'Monaco'},
{'value':'Mongolia','label':'Mongolia'},
{'value':'Montenegro','label':'Montenegro'},
{'value':'Morocco','label':'Morocco'},
{'value':'Mozambique','label':'Mozambique'},
{'value':'Myanmar','label':'Myanmar'},
{'value':'Namibia','label':'Namibia'},
{'value':'Nauru','label':'Nauru'},
{'value':'Nepal','label':'Nepal'},
{'value':'Netherlands','label':'Netherlands'},
{'value':'New Zealand','label':'New Zealand'},
{'value':'Nicaragua','label':'Nicaragua'},
{'value':'Niger','label':'Niger'},
{'value':'Nigeria','label':'Nigeria'},
{'value':'North Macedonia','label':'North Macedonia'},
{'value':'Norway','label':'Norway'},
{'value':'Oman','label':'Oman'},
{'value':'Pakistan','label':'Pakistan'},
{'value':'Palau','label':'Palau'},
{'value':'Panama','label':'Panama'},
{'value':'Papua New Guinea','label':'Papua New Guinea'},
{'value':'Paraguay','label':'Paraguay'},
{'value':'Peru','label':'Peru'},
{'value':'Philippines','label':'Philippines'},
{'value':'Poland','label':'Poland'},
{'value':'Portugal','label':'Portugal'},
{'value':'Qatar','label':'Qatar'},
{'value':'Romania','label':'Romania'},
{'value':'Russian Federation','label':'Russian Federation'},
{'value':'Rwanda','label':'Rwanda'},
{'value':'Saint Kitts and Nevis','label':'Saint Kitts and Nevis'},
{'value':'Saint Lucia','label':'Saint Lucia'},
{'value':'Saint Vincent and the Grenadines','label':'Saint Vincent and the Grenadines'},
{'value':'Samoa','label':'Samoa'},
{'value':'San Marino','label':'San Marino'},
{'value':'Sao Tome and Principe','label':'Sao Tome and Principe'},
{'value':'Saudi Arabia','label':'Saudi Arabia'},
{'value':'Senegal','label':'Senegal'},
{'value':'Serbia','label':'Serbia'},
{'value':'Seychelles','label':'Seychelles'},
{'value':'Sierra Leone','label':'Sierra Leone'},
{'value':'Singapore','label':'Singapore'},
{'value':'Slovakia','label':'Slovakia'},
{'value':'Slovenia','label':'Slovenia'},
{'value':'Solomon Islands','label':'Solomon Islands'},
{'value':'Somalia','label':'Somalia'},
{'value':'South Africa','label':'South Africa'},
{'value':'South Sudan','label':'South Sudan'},
{'value':'Spain','label':'Spain'},
{'value':'Sri Lanka','label':'Sri Lanka'},
{'value':'Sudan','label':'Sudan'},
{'value':'Surilabel','label':'Surilabel'},
{'value':'Sweden','label':'Sweden'},
{'value':'Switzerland','label':'Switzerland'},
{'value':'Syrian Arab Republic','label':'Syrian Arab Republic'},
{'value':'Tajikistan','label':'Tajikistan'},
{'value':'Tanzania, United Republic of','label':'Tanzania, United Republic of'},
{'value':'Thailand','label':'Thailand'},
{'value':'Timor-Leste','label':'Timor-Leste'},
{'value':'Togo','label':'Togo'},
{'value':'Tonga','label':'Tonga'},
{'value':'Trinidad and Tobago','label':'Trinidad and Tobago'},
{'value':'Tunisia','label':'Tunisia'},
{'value':'Türkiye','label':'Türkiye'},
{'value':'Turkmenistan','label':'Turkmenistan'},
{'value':'Tuvalu','label':'Tuvalu'},
{'value':'Uganda','label':'Uganda'},
{'value':'Ukraine','label':'Ukraine'},
{'value':'United Arab Emirates','label':'United Arab Emirates'},
{'value':'United Kingdom of Great Britain and Northern Ireland','label':'United Kingdom of Great Britain and Northern Ireland'},
{'value':'United States of America','label':'United States of America'},
{'value':'Uruguay','label':'Uruguay'},
{'value':'Uzbekistan','label':'Uzbekistan'},
{'value':'Vanuatu','label':'Vanuatu'},
{'value':'Venezuela (Bolivarian Republic of)','label':'Venezuela (Bolivarian Republic of)'},
{'value':'Viet Nam','label':'Viet Nam'},
{'value':'Yemen','label':'Yemen'},
{'value':'Zambia','label':'Zambia'},
{'value':'Zimbabwe','label':'Zimbabwe'}
  ]

  export const riggingCertificates = [
    { value: 1, label: 'NRC1' },
    { value: 2, label: 'NRC2' },
    { value: 3, label: 'NRC3' },
    { value: 4, label: 'ETCP' },
    { value: 5, label: 'SQQ2' },
    { value: 6, label: 'SQQ3' },
    { value: 7, label: 'OTHER' }
  ]

  export const abseilingCertificates = [
    { value: 1, label: 'IRATA1' },
    { value: 2, label: 'IRATA2' },
    { value: 3, label: 'IRATA3' },
    { value: 4, label: 'FISAT1' },
    { value: 5, label: 'FISAT2' },
    { value: 6, label: 'FISAT3' },
    { value: 7, label: 'SPRAT1' },
    { value: 8, label: 'SPRAT2' },
    { value: 9, label: 'SPRAT3' },
    { value: 10, label: 'OTHER' }
  ]

  export const mewpCertificates = [
    { value: 1, label: 'IPAF1a' },
    { value: 2, label: 'IPAF1b' },
    { value: 3, label: 'IPAF3a' },
    { value: 4, label: 'IPAF3b' },
    { value: 5, label: 'OTHER' }
  ]

  export const riggingSpecialities = [
    { value: 1, label: 'HEAD RIGGER' },
    { value: 2, label: 'UP RIGGER' },
    { value: 3, label: 'GROUNDER' },
    { value: 4, label: 'TRUSS MASTER' },
    { value: 5, label: 'HOIST CONTROL' },
    { value: 6, label: 'OTHER' }
  ]

  export const automations = [
    { value: 1, label: 'KINESYS' },
    { value: 2, label: 'MOVECAT' },
    { value: 3, label: 'CYBERHOIST' },
    { value: 4, label: 'CHAINMASTER' },
    { value: 5, label: 'TAIT' },
    { value: 6, label: 'OTHER' }
  ]

  export const cad = [
    { value: 1, label: 'AUTOCAD' },
    { value: 2, label: 'VECTORWORKS' },
    { value: 3, label: 'OTHER' }
  ]

  export const hoistInspections = [
    { value: 1, label: 'LIFTKET' },
    { value: 2, label: 'MOVECAT' },
    { value: 3, label: 'CHAINMASTER' },
    { value: 4, label: 'CM' },
    { value: 5, label: 'EXE' },
    { value: 6, label: 'OTHER' }
  ]

  export const yearsOfExperienceList = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2-3', label: '2-3' },
    { value: '4-6', label: '4-6' },
    { value: '7-10', label: '7-10' },
    { value: 'Above', label: 'Above' }
  ]


  
  export const languages = [
    { value: 1, label: 'English' },
    { value: 2, label: 'Spanish' },
    { value: 3, label: 'German' },
    { value: 4, label: 'Polish' },
    { value: 5, label: 'Italian' },
    { value: 6, label: 'Other' }
  ]

  export const supportedDrivingLicenses = [
    { value: 1, label: 'A' },
    { value: 2, label: 'B' },
    { value: 3, label: 'C' }
  ]