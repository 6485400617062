import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import ContactStore from "./contactStore";
import GigStore from "./gigStore";
import ProfileStore from "./profileStore";
import UserStore from "./userStore";

interface Store {
    gigStore: GigStore;
    userStore: UserStore;
    commonStore: CommonStore;
    profileStore: ProfileStore;
    contactStore: ContactStore;
}

export const store: Store = {
    gigStore: new GigStore(),
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    profileStore: new ProfileStore(),
    contactStore: new ContactStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}