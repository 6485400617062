import { Fragment } from 'react'
import { Disclosure, Listbox, Menu, Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, UserCircleIcon, ChevronDownIcon, ArrowPathIcon, BookmarkSquareIcon, CalendarIcon, ChartBarIcon, CursorArrowRaysIcon, LifebuoyIcon, PhoneIcon, PlayIcon, ShieldCheckIcon, Squares2X2Icon, HomeIcon, HomeModernIcon } from '@heroicons/react/24/outline'
import { Link, NavLink } from 'react-router-dom'
import { useStore } from '../stores/store'
import { observer } from 'mobx-react-lite'

const navigation = [
  { name: 'Home', href: '/' },
  // { name: 'Gigs', href: '/gigs' },
  // { name: 'Calendar', href: '/calendar' },
  // { name: 'Errors', href: '/errors' },
]

const solutions = [
  {
    name: 'Home',
    description: 'Stage Riggers Homepage',
    href: '/',
    icon: HomeIcon,
  }
]
const callsToAction = [
  { name: 'Watch Demo', href: '#', icon: PlayIcon },
  { name: 'Contact Sales', href: '#', icon: PhoneIcon },
]

function classNames(...classes : any) {
  return classes.filter(Boolean).join(' ')
}

function Footer() {

  const {userStore: {user, logout, isLoggedIn}} = useStore();


  return (
<footer className="bg-white shadow dark:bg-gray-900 w-full mt-auto">
    <div className="mx-auto w-full max-w-screen-xl p-10">
        <div className="flex justify-center items-center flex-col md:flex-row md:items-start md:justify-start">
          <div className="mb-6 text-center md:text-left md:mb-0">
          <Link className="flex items-center mb-4 justify-center md:justify-start" to='/'>
            <img src='/assets/logo.svg' className="h-8 mr-3 w-auto bg-white rounded-lg p-1" alt='Stage Riggers' />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Stage Riggers</span>
            </Link>
            <ul className="text-gray-600 dark:text-gray-400 font-medium">
                      <li className="mb-4">
                      SHOWSTAK AUTOMATION Sp. z o.o.
                      </li>
                      <li className="mb-4">
                      Gospodarcza 26
                      </li>
                      <li className="mb-4">
                      Lublin 20-213
                      </li>
                      <li className="mb-4">
                      VAT: PL 9462696213
                      </li>
            </ul>
          </div>
          <div className="flex md:ml-auto justify-center md:justify-end">
              <div className="text-center md:text-left md:mx-4">
                  <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
                  <ul className="text-gray-600 dark:text-gray-400 font-medium">
                      <li className="mb-4">
                        <Link to="/privacypolicy" className="hover:underline">Privacy Policy</Link>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <span className="block text-sm text-gray-500 text-center dark:text-gray-400">
          © 2023
          <span className="ml-1">
            Stage Riggers™
          </span>
          . All Rights Reserved.
        </span>
    </div>
</footer>


    
  )
}
export default observer(Footer);