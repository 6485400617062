import { Form, Formik } from 'formik'
import React from 'react'
import { MyTextInput } from '../common/form/MyTextInput'
import { useStore } from '../stores/store'
import { observer } from 'mobx-react-lite';
import { Spinner } from 'flowbite-react';
import * as Yup from 'yup';
import useQuery from '../common/util/hooks';
import { ValidationErrors } from '../../features/errors/ValidationErrors';

export default observer(function PasswordResetPage() {

  const {userStore} = useStore();
  const token = useQuery().get('token') as string;
  const email = useQuery().get('email') as string;

  const validationSchema = Yup.object({
    password: Yup.string().required('Password is required').matches(
      /.{8,20}$/,
      "Must be between 8 and 20 characters long"
    ),
    passwordRepeat: Yup.string().required().oneOf([Yup.ref('password'), null], 'Passwords must match')
    })

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md">
                  <div>
                  <img src='/assets/logo.svg' className="mx-auto h-36 w-auto rounded-lg" alt='Stage Riggers' />
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                      Please set a new password
                    </h2>
                  </div>
                  <Formik
                  validationSchema={validationSchema}
                    initialValues={{password:'', passwordRepeat:'', error: null}}
                    onSubmit={(values, {setErrors}) => userStore.resetPassword({token: token, email: email, password: values.password}).catch(error =>{
                      setErrors({error});
                    })}
                    >
                      {({handleSubmit, isSubmitting, errors, isValid, dirty}) => (
                        <Form className="mt-8" autoComplete='off' onSubmit={handleSubmit}>
                            <MyTextInput placeholder={'Password'} name={'password'} type={'password'} required={true} label={'Your password'} className='mb-2' />
                            <MyTextInput placeholder={'Repeat password'} name={'passwordRepeat'} type={'password'} required={true} label={'Repeat password'} className='mb-3' />
                            { errors !== undefined && errors !== null && <ValidationErrors errors={errors.error} /> }
                          <div className='mt-3'>
                            <button
                              type="submit"
                              disabled={!isValid || !dirty || isSubmitting}
                              className="group relative disabled:opacity-50 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              {isSubmitting ? (<Spinner aria-label="large spinner" size="md" color="purple" />) : ("Submit") }
                            </button>
                          </div>
                        </Form>
                      )}
                  </Formik>
                  </div>
              </div>
  )
})
