import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Alert } from 'flowbite-react';
import React from 'react'

interface Props {
    className?: string;
    errors: any;
}

export const ValidationErrors = (props: Props) => {
  return (
    <>
        {props.errors && (
            <Alert
            color="failure" className={props.className}
            icon={ExclamationTriangleIcon}
          >
            {props.errors.map((err: any, i: any) => (
                            <span key={i} className="font-medium">
                              {' '}{err}
                            </span>
                ))}
            </Alert>
        )}
    </>
  )
}
