import { Form, Formik } from 'formik'
import React from 'react'
import { MyTextInput } from '../common/form/MyTextInput'
import { useStore } from '../stores/store'
import { observer } from 'mobx-react-lite';
import { Spinner } from 'flowbite-react';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { ValidationErrors } from '../../features/errors/ValidationErrors';

export default observer(function SignInPage() {

  const {userStore} = useStore();

  const validationSchema = Yup.object({
    email: Yup.string().required('Email is required').email(),
    password: Yup.string().required('Password is required'),
})

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md">
                  <div>
                  <img src='/assets/logo.svg' className="mx-auto h-36 w-auto rounded-lg" alt='Stage Riggers' />
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                      Sign in to your account
                    </h2>
                  </div>
                  <Formik
                  validationSchema={validationSchema}
                    initialValues={{email:'', password:'', error: null}}
                    onSubmit={(values, {setErrors}) => userStore.login(values).catch(error =>
                      setErrors({error}))}
                    >
                      {({handleSubmit, isSubmitting, isValid, dirty, errors}) => (
                        <Form className="mt-8" onSubmit={handleSubmit} autoComplete='off'>
                          <MyTextInput placeholder={'Email address'} name={'email'} type={'email'} required={true} label={'Your email'} className='mb-2' />
                          <MyTextInput placeholder={'Password'} name={'password'} type={'password'} required={true} label={'Your password'} className='mb-3' />
                          { errors !== undefined && errors !== null && <ValidationErrors errors={errors.error} /> }
                          <div className="flex items-center justify-between">
                      <div className="text-sm my-2">
                        <Link to='/requestPasswordReset' className="font-medium text-indigo-600 hover:text-indigo-500">
                          Forgot your password?
                        </Link>
                      </div>
                    </div>
                          <div className='mt-3'>
                            <button
                              type="submit"
                              disabled={!isValid || !dirty || isSubmitting}
                              className="group relative disabled:opacity-50 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              {isSubmitting ? (<Spinner aria-label="large spinner" size="md" color="purple" />) : ("Sign in") }
                            </button>
                          </div>
                        </Form>
                      )}
                  </Formik>
                  </div>
              </div>
  )
})
