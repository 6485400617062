import React, { useState } from 'react';
import axios from 'axios';
import { ValidationErrors } from './ValidationErrors';

export default function TestErrors() {
    const baseUrl = process.env.REACT_APP_API_URL;
    const [errors, setErrors] = useState(null);

    function handleNotFound() {
        axios.get(baseUrl + '/buggy/not-found').catch(err => console.log(err.response));
    }

    function handleBadRequest() {
        console.log(baseUrl + '/buggy/bad-request');
        axios.get(baseUrl + '/buggy/bad-request').catch(err => console.log(err.response));
    }

    function handleServerError() {
        axios.get(baseUrl + '/buggy/server-error').catch(err => console.log(err.response));
    }

    function handleUnauthorised() {
        axios.get(baseUrl + '/buggy/unauthorised').catch(err => console.log(err.response));
    }

    function handleBadGuid() {
        axios.get(baseUrl + '/gigs/notaguid').catch(err => console.log(err.response));
    }

    function handleValidationError() {
        axios.get(baseUrl + '/buggy/bad-request-errors').catch(err => setErrors(err));
    }

    return (
        <>
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Test Error component</h1>
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-y-16 gap-x-8 py-12 px-4 sm:px-6 sm:py-18 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <button className="drop-shadow-xl relative flex mt-5 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={handleNotFound}>Not Found</button>
                <button className="drop-shadow-xl relative flex mt-5 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={handleBadRequest}>Bad Request</button>
                <button className="drop-shadow-xl relative flex mt-5 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={handleValidationError}>Validation Error</button>
                <button className="drop-shadow-xl relative flex mt-5 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={handleServerError}>Server Error</button>
                <button className="drop-shadow-xl relative flex mt-5 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={handleUnauthorised}>Unauthorized</button>
                <button className="drop-shadow-xl relative flex mt-5 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={handleBadGuid}>Bad Guid</button>
            </div>
            {errors && <ValidationErrors errors={errors} />}
        </>
    )
}